import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form';

interface FormCheckboxProps extends UseControllerProps {
  label?: string | React.ReactElement;
  autoFocus?: boolean;
  disabled?: boolean;
  defaultValue?: boolean;
}

export default function FormCheckbox({ label, name, rules, autoFocus, disabled, defaultValue }: FormCheckboxProps) {
  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const hasError = !!errors[name];
  const errorMessage = errors[name]?.message;

  return (
    <FormControl error={hasError}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue ?? false}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                {...field}
                checked={field.value || false}
                autoFocus={autoFocus}
                disabled={isSubmitting || disabled}
                color="primary"
              />
            }
            label={label}
          ></FormControlLabel>
        )}
      />
      {hasError && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}
