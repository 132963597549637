import * as storage from '../../common/helpers/storage.helpers';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AuthenticationState } from './authentication.interfaces';

const initialState: AuthenticationState = {
  isAccountCloseConfirmFormOpen: false,
  isLoggedIn: storage.getLoggedIn(),
  isForbidden: false,
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setLoggedIn: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoggedIn = payload;
      storage.setLoggedIn(payload);
    },
    setForbidden: (state, { payload }: PayloadAction<boolean>) => {
      state.isForbidden = payload;
    },
    openAccountCloseConfirmForm: (state) => {
      state.isAccountCloseConfirmFormOpen = true;
    },
    closeAccountCloseConfirmForm: (state) => {
      state.isAccountCloseConfirmFormOpen = false;
    },
  },
});

export const { setLoggedIn, setForbidden } = authenticationSlice.actions;

export default authenticationSlice.reducer;
