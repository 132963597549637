import { InternalBusiness, InternalEmployment, InternalTaxData, TaxFormState } from './tax-form.interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: TaxFormState = {
  isOpen: false,
  step: 0,
  businessHistory: [],
  taxDataHistory: [],
  employmentHistory: [],
};

export const taxFormSlice = createSlice({
  name: 'taxForm',
  initialState,
  reducers: {
    open: (state) => {
      state.isOpen = true;
    },
    cancel: (state) => {
      state.isOpen = false;
      state.step = initialState.step;
      state.businessHistory = initialState.businessHistory;
      state.taxDataHistory = initialState.taxDataHistory;
      state.employmentHistory = initialState.employmentHistory;
    },
    setBusinessHistory: (state, { payload }: PayloadAction<InternalBusiness[]>) => {
      state.businessHistory = payload;
    },
    setTaxDataHistory: (state, { payload }: PayloadAction<InternalTaxData[]>) => {
      state.taxDataHistory = payload;
    },
    setEmploymentHistory: (state, { payload }: PayloadAction<InternalEmployment[]>) => {
      state.employmentHistory = payload;
    },
    incrementStep: (state) => {
      state.step++;
    },
    decrementStep: (state) => {
      state.step--;
    },
  },
});

export default taxFormSlice.reducer;
