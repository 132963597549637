import { useAppDispatch, useAppSelector } from '../hooks';
import { useCallback, useMemo } from 'react';

import { OpenConfirmationDialog } from './confirmation-dialog.interfaces';
import { confirmationDialogSelector } from './confirmation-dialog.selectors';
import { confirmationDialogSlice } from './confirmation-dialog.slice';
import { openConfirmationDialog } from './confirmation-dialog.thunks';

function useConfirmationDialog() {
  const dispatch = useAppDispatch();
  const { isOpen, title, message } = useAppSelector(confirmationDialogSelector);

  const open = useCallback(
    async (data: OpenConfirmationDialog) => {
      return dispatch(openConfirmationDialog(data));
    },
    [dispatch],
  );

  const confirm = useCallback(() => {
    return dispatch(confirmationDialogSlice.actions.confirm());
  }, [dispatch]);

  const cancel = useCallback(() => {
    return dispatch(confirmationDialogSlice.actions.cancel());
  }, [dispatch]);

  return useMemo(
    () => ({
      isOpen,
      title,
      message,
      open,
      confirm,
      cancel,
    }),
    [isOpen, title, message, open, confirm, cancel],
  );
}

export default useConfirmationDialog;
