import { NotificationsState, ShowNotification } from './notifications.interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: NotificationsState = {
  isOpen: false,
  severity: 'info',
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    show: (state, { payload }: PayloadAction<ShowNotification>) => {
      state.isOpen = true;
      state.message = payload.message;
      state.severity = payload.severity;
      state.duration = payload.duration;
    },
    hide: (state) => {
      state.isOpen = false;
      state.message = initialState.message;
      state.severity = initialState.severity;
      state.duration = initialState.duration;
    },
  },
});

export default notificationsSlice.reducer;
