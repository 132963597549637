import store, { RootState } from '../store';

import { OpenConfirmationDialog } from './confirmation-dialog.interfaces';
import { confirmationDialogSlice } from './confirmation-dialog.slice';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const openConfirmationDialog = createAsyncThunk<boolean, OpenConfirmationDialog, { state: RootState }>(
  'confirmationDialog/open',
  async (request, { getState, dispatch }): Promise<boolean> => {
    const { action, ...data } = request;

    dispatch(confirmationDialogSlice.actions.open(data));

    return new Promise<boolean>((resolve) => {
      const unsubscribe = store.subscribe(async () => {
        const { isCancelled, isConfirmed } = getState().confirmationDialog;

        if (isConfirmed) {
          unsubscribe();

          if (action) {
            await dispatch(action);
          }

          resolve(true);
        }

        if (isCancelled) {
          unsubscribe();
          resolve(false);
        }
      });
    });
  },
);
