import { createSlice } from '@reduxjs/toolkit';
import { PortalLoginDialogState } from './portal-login-dialog.interfaces';

const initialState: PortalLoginDialogState = {
  isOpen: false,
  isCancelled: false,
  isConfirmed: false,
};

export const portalLoginDialogSlice = createSlice({
  name: 'portalLoginDialog',
  initialState,
  reducers: {
    open: (state) => {
      state.isOpen = true;
      state.isConfirmed = false;
      state.isCancelled = false;
    },
    confirm: (state) => {
      state.isOpen = false;
      state.isConfirmed = true;
      state.isCancelled = false;
    },
    cancel: (state) => {
      state.isOpen = false;
      state.isConfirmed = false;
      state.isCancelled = true;
    },
  },
});

export default portalLoginDialogSlice.reducer;
