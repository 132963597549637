import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { FullTaxFormStepMessage, FullTaxFormStepRef } from './tax-form.interfaces';
import { useRef, useState } from 'react';

import BusinessDetailsStep from './business-details.step';
import EmploymentDetailsStep from './employment-details.step';
import TaxDetailsStep from './tax-details.step';
import { useGetProfileQuery } from '../../redux/authentication/authentication.api';
import useNotifications from '../../redux/notifications/notifications.hooks';
import useTaxForm from '../../redux/tax-form/tax-form.hooks';
import { useUpdateUserDetailsMutation } from '../../redux/user-details/user-details.api';

interface StepData {
  title: string;
  description: string;
}

const STEP_DATA: StepData[] = [
  {
    title: 'Início de Actividade',
    description:
      'Indique quando abriu actividate. E se reabriu actividade durante os primeiros 12 meses ou os primeiros 5 anos, por favor indique também quando foi feito o fecho e reabertura.',
  },
  {
    title: 'Dados Fiscais',
    description:
      'Vamos necessitar de alguns dos seus dados fiscais para que os cáclulos sejam mais exactos. Caso a sua situação fiscal se tenha alterado enquanto teve a actividade aberta, pode adicionar a informação mais antiga.',
  },
  {
    title: 'Emprego por conta de outrém',
    description:
      'Se for empregado por contra de outrém durante período relevante, por favor preencha esta secção. Insira os diferentes salários base que auferiu durante o periodo.',
  },
];

function getStepData(index: number): StepData {
  if (index < STEP_DATA.length) {
    return STEP_DATA[index];
  }
  return {
    title: '-',
    description: '-',
  };
}

export default function FullTaxFormDialog() {
  const [stepMessages, setStepMessages] = useState<FullTaxFormStepMessage[]>(STEP_DATA.map(() => null));
  const [update, { isLoading, error }] = useUpdateUserDetailsMutation();
  const { refetch } = useGetProfileQuery();
  const { show } = useNotifications();
  const { isOpen, step, taxDataHistory, businessHistory, employmentHistory, cancel, incrementStep, decrementStep } =
    useTaxForm();
  const stepRef = useRef<FullTaxFormStepRef | null>(null);

  const stepData = getStepData(step);

  const handleClose = () => {
    if (!isLoading) {
      cancel();
    }
  };

  const handleNextClick = async () => {
    const message = await stepRef.current?.submit();

    const newMessages = [...stepMessages];
    newMessages[step] = message;
    setStepMessages(newMessages);

    if (message) {
      return;
    }

    if (step < STEP_DATA.length - 1) {
      incrementStep();
    } else {
      try {
        await update({ taxDataHistory, businessHistory, employmentHistory }).unwrap();
        show({
          message: 'Registo finalizado com sucesso.',
          severity: 'success',
          duration: 5000,
        });
        refetch();
        cancel();
      } catch (e) {
        // Already handled in the 'error' variable
      }
    }
  };

  const handlePreviousClick = () => {
    decrementStep();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg">
      <DialogTitle>Completar Registo - {stepData.title}</DialogTitle>
      <DialogContent>
        <Typography paragraph gutterBottom>
          {stepData.description}
        </Typography>
        <Box padding={2}>
          {step === 0 && <BusinessDetailsStep mRef={stepRef} />}
          {step === 1 && <TaxDetailsStep mRef={stepRef} />}
          {step === 2 && <EmploymentDetailsStep mRef={stepRef} />}
        </Box>
        {error && (
          <Box textAlign="center">
            <Typography color="error">Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.</Typography>
          </Box>
        )}
        <Stepper activeStep={step}>
          {STEP_DATA.map(({ title }, index) => {
            const isComplete = step > index;
            const message = stepMessages[index];

            const hasError = !!message;
            const optional = hasError ? (
              <Typography variant="caption" color="error">
                {message}
              </Typography>
            ) : undefined;

            return (
              <Step key={index} completed={isComplete}>
                <StepLabel error={hasError} optional={optional}>
                  {title}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </DialogContent>
      <DialogActions>
        {step > 0 && (
          <Button onClick={handlePreviousClick} color="primary" disabled={isLoading}>
            Anterior
          </Button>
        )}
        {step < STEP_DATA.length - 1 && (
          <Button onClick={handleNextClick} color="primary" disabled={isLoading}>
            Próximo
          </Button>
        )}
        {step === STEP_DATA.length - 1 && (
          <Button onClick={handleNextClick} color="primary" disabled={isLoading}>
            Concluír
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
