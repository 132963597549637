export function setLoggedIn(isLoggedIn: boolean): void {
  try {
    if (isLoggedIn) {
      localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
    } else {
      localStorage.removeItem('isLoggedIn');
    }
  } catch (e) {
    console.error(e);
  }
}

export function getLoggedIn(): boolean {
  const value = localStorage.getItem('isLoggedIn');
  return !!value;
}
