import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import NavListTitle from './nav-list-title';
import React from 'react';

interface NavListSectionProps {
  title?: string;
  divider?: boolean;
  padding?: 'normal' | 'small';
  children: React.ReactNode | React.ReactNode[];
}

const useStyles = makeStyles((theme) => ({
  root: (props: { padding?: 'normal' | 'small' }) => {
    if (props.padding === 'small') {
      return {
        padding: '14px 24px 14px 24px',
      };
    } else {
      return {
        padding: '20px 24px 20px 24px',
      };
    }
  },
  title: {
    padding: '0 18px',
  },
}));

export default function NavListSection({ title, divider, padding, children }: NavListSectionProps) {
  const classes = useStyles({ padding });
  return (
    <>
      <div className={classes.root}>
        {title && <NavListTitle text={title} className={classes.title} />}
        {children}
      </div>
      {divider && <Divider />}
    </>
  );
}
