import { Controller, UseControllerProps, useFormContext } from 'react-hook-form';
import { FormControl, FormHelperText, InputLabel, ListSubheader, MenuItem, Select } from '@material-ui/core';

import React from 'react';

export interface FormSelectItem {
  label?: string;
  value?: string | number;
}

export interface FormSelectCategory {
  title: string;
  items: FormSelectItem[];
}

export interface FormSelectProps extends UseControllerProps {
  items: FormSelectItem[] | FormSelectCategory[];
  label?: string | null;
  helperText?: string | null;
  fullWidth?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  defaultValue?: string | number;
}

export default function FromSelect({ name, rules, items, label, helperText, defaultValue, ...other }: FormSelectProps) {
  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const hasError = !!errors[name];

  const getHelpText = () => {
    if (errors[name]?.message) {
      return errors[name]?.message;
    }

    let message = helperText;
    switch (errors[name]?.type) {
      case 'required':
        message = 'Campo obrigatório';
        break;
      default:
        break;
    }

    return message;
  };

  const createItemList = (): React.ReactElement[] => {
    const list: React.ReactElement[] = [];

    items.forEach((item) => {
      if ('title' in item) {
        list.push(<ListSubheader key={`option-${item.title}`}>{item.title}</ListSubheader>);
        item.items.map(({ label, value }) =>
          list.push(
            <MenuItem key={`option-${value}`} value={value}>
              {label}
            </MenuItem>,
          ),
        );
      } else {
        const { label, value } = item;
        list.push(
          <MenuItem key={`option-${value}`} value={value}>
            {label}
          </MenuItem>,
        );
      }
    });

    return list;
  };

  const rederedHelperText = getHelpText();
  return (
    <FormControl {...other} error={hasError}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select id={name} inputRef={field.ref} {...field} disabled={isSubmitting}>
            {createItemList()}
          </Select>
        )}
      />
      {rederedHelperText && <FormHelperText>{rederedHelperText}</FormHelperText>}
    </FormControl>
  );
}
