import { ChangePlan } from '../../shared/dtos/subscription.interface';
import { api } from '../api';

export const subscriptionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    changePlan: builder.mutation<void, ChangePlan>({
      query: (body) => ({
        url: 'billing/subscriptions/change-plan',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Profile'],
    }),
  }),
  overrideExisting: false,
});

export const { useChangePlanMutation } = subscriptionsApi;
