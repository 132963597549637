import { Avatar, ButtonBase, Grid, IconButton, Typography } from '@material-ui/core';
import { useGetProfileQuery, useLogoutMutation } from '../../redux/authentication/authentication.api';

import { PowerSettingsNew } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import useAuthentication from '../../redux/authentication/authentication.hooks';
import useNavigation from '../nav.hook';

const useStyles = makeStyles((theme) => ({
  name: {
    fontWeight: 'bold',
    lineHeight: '16px',
    maxWidth: '120px',
  },
  avatar: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function NavUser() {
  const classes = useStyles();
  const { navToAccount } = useNavigation();
  const { data } = useGetProfileQuery();
  const [logout] = useLogoutMutation();
  const { setLoggedIn } = useAuthentication();

  const handleAccount = () => {
    navToAccount();
  };

  const handleLogout = async () => {
    try {
      await logout().unwrap();
    } catch (e) {
      // Ignore
    }

    setLoggedIn(false);
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={3}>
        <ButtonBase onClick={handleAccount}>
          <Avatar className={classes.avatar}>{data?.name ? data.name.substring(0, 1).toUpperCase() : ''}</Avatar>
        </ButtonBase>
      </Grid>
      <Grid item xs={9}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="body1" display="block" className={classes.name}>
              {data?.name}
            </Typography>
            <ButtonBase onClick={handleAccount}>
              <Typography variant="body2" component="a" color="primary">
                Ver perfil
              </Typography>
            </ButtonBase>
          </Grid>
          <Grid item>
            <IconButton aria-label="logout" onClick={handleLogout}>
              <PowerSettingsNew />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
