import { Entity, EntityParams, EntityUpdate } from '../../shared/dtos/entities.interface';

import { api } from '../api';

export const entitiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEntities: builder.query<Entity[], EntityParams>({
      query: (params) => ({
        url: `/entities`,
        params,
      }),
      providesTags: ['Entities'],
    }),
    createEntity: builder.mutation<Entity, EntityUpdate>({
      query: (body) => ({
        url: `/entities`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Entities'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetEntitiesQuery, useLazyGetEntitiesQuery, useCreateEntityMutation } = entitiesApi;
