import { MonthlyParams, MonthlyTaxes, QuarterParams, QuarterTaxes, Taxes } from '../../shared/dtos/taxes.interface';

import { api } from '../api';

export const taxesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTaxes: builder.query<Taxes, void>({
      query: () => ({
        url: 'taxes',
      }),
      providesTags: ['Taxes'],
    }),
    getQuarterTaxes: builder.query<QuarterTaxes, QuarterParams>({
      query: (params: QuarterParams) => ({
        url: 'taxes/quarter',
        params,
      }),
      providesTags: ['Taxes'],
    }),
    getMonthlyTaxes: builder.query<MonthlyTaxes, MonthlyParams>({
      query: (params: MonthlyParams) => ({
        url: 'taxes/monthly',
        params,
      }),
      providesTags: ['Taxes'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetTaxesQuery, useGetQuarterTaxesQuery, useGetMonthlyTaxesQuery } = taxesApi;
