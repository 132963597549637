import {
  DataTableHeaderDefinition,
  DataTablePagination,
  DataTableRowDefinition,
  RowAction,
} from './data-table.interfaces';
import {
  Hidden,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';

import DataTableRow from './data-table-row';
import { StyledTableCell } from './data-table-cell';

export interface DataTableProps<T> {
  headers: DataTableHeaderDefinition<T>[];
  items: DataTableRowDefinition<T>[];
  pagination?: DataTablePagination;
  isLoading?: boolean;
  actions?: RowAction[];
  emptyHeight?: string;
  onLoading?: () => React.ReactNode | null;
  onEmpty?: () => React.ReactNode | null;
  onRowEdit?: (row: T, index: number) => void;
  onRowDelete?: (row: T, index: number) => void;
}

export type DataTableOrder = 'asc' | 'desc';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
}));

export default function DataTable<T>(props: DataTableProps<T>) {
  const { headers, items, pagination, isLoading, actions, emptyHeight, onLoading, onEmpty, onRowEdit, onRowDelete } =
    props;
  const classes = useStyles();
  const theme = useTheme();
  const downXs = useMediaQuery(theme.breakpoints.down('xs'));

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    pagination?.onChangeRowsPerPage(rowsPerPage);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    let offset = 0;
    if (pagination?.rowsPerPage) {
      offset = newPage * pagination.rowsPerPage;
    }

    if (pagination?.onChangePage) {
      pagination?.onChangePage(newPage);
    }

    if (pagination?.onChangeOffset) {
      pagination?.onChangeOffset(offset);
    }
  };

  const calcSpan = (span?: number): string => {
    if (span) {
      return `${span * 5}%`;
    }
    return 'none';
  };

  const renderBody = (): React.ReactElement | React.ReactElement[] | undefined => {
    if (isLoading && onLoading) {
      return (
        <TableRow style={{ height: emptyHeight ?? '500px' }}>
          <StyledTableCell align="center" colSpan={headers.length}>
            {onLoading()}
          </StyledTableCell>
        </TableRow>
      );
    } else if (items.length === 0 && onEmpty) {
      return (
        <TableRow style={{ height: emptyHeight ?? '500px' }}>
          <StyledTableCell align="center" colSpan={headers.length}>
            {onEmpty()}
          </StyledTableCell>
        </TableRow>
      );
    } else {
      return items.map((row, index) => (
        <DataTableRow
          key={index}
          headers={headers}
          index={index}
          row={row}
          actions={actions}
          onRowEdit={onRowEdit}
          onRowDelete={onRowDelete}
        />
      ));
    }
  };

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table size={downXs ? 'small' : 'medium'}>
          <TableHead>
            <TableRow>
              {headers.map((h, index) => (
                <StyledTableCell key={`header-${index}`} align={h.align ?? 'left'} style={{ width: calcSpan(h.span) }}>
                  {h.name}
                </StyledTableCell>
              ))}
              {actions?.length && (
                <StyledTableCell key="header-actions" align="right" style={{ width: '10%' }}>
                  Acções
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>{renderBody()}</TableBody>
        </Table>
      </TableContainer>
      {pagination && pagination.count > 0 && (
        <TablePagination
          component="div"
          rowsPerPageOptions={pagination.rowsPerPageOptions}
          count={pagination.count}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          labelRowsPerPage={<Hidden mdDown>Itens por página</Hidden>}
        />
      )}
    </div>
  );
}
