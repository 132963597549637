import {
  Expense,
  ExpenseList,
  ExpenseParams,
  ExpenseUpdate,
  ExpensesImport,
} from '../../shared/dtos/expenses.interface';

import { api } from '../api';

export const expensesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    importExpenses: builder.mutation<void, ExpensesImport>({
      query: (body) => ({
        url: `/expenses/import`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Expenses', 'Taxes', 'Dashboard'],
    }),
    getExpenses: builder.query<ExpenseList, ExpenseParams>({
      query: (params) => ({
        url: `/expenses`,
        params: { ...params },
      }),
      providesTags: ['Expenses'],
    }),
    createExpense: builder.mutation<Expense, ExpenseUpdate>({
      query: (body) => ({
        url: `/expenses`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Expenses', 'Taxes', 'Dashboard'],
    }),
    updateExpense: builder.mutation<Expense, ExpenseUpdate & Pick<Expense, 'id'>>({
      query: ({ id, ...body }) => ({
        url: `/expenses/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Expenses', 'Taxes', 'Dashboard'],
    }),
    deleteExpense: builder.mutation<void, number>({
      query: (id) => ({
        url: `/expenses/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Expenses', 'Taxes', 'Dashboard'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useImportExpensesMutation,
  useGetExpensesQuery,
  useCreateExpenseMutation,
  useUpdateExpenseMutation,
  useDeleteExpenseMutation,
} = expensesApi;
