import Button, { ButtonProps } from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean;
  children?: React.ReactElement | React.ReactElement[] | string;
}

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -8,
  },
}));

export default function LoadingButton(props: LoadingButtonProps) {
  const classes = useStyles();
  const { isLoading, children, ...other } = props;

  return (
    <Button disabled={isLoading} {...other}>
      {children}
      {isLoading && (
        <CircularProgress
          color={other.color === 'default' ? 'primary' : other.color}
          size={16}
          className={classes.buttonProgress}
        />
      )}
    </Button>
  );
}
