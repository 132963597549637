import * as yup from 'yup';

import { Box, Button, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import {
  DisabilityDegrees,
  MaritalStatus,
  TaxBenefitType,
  TaxData,
  TaxLocation,
  maritalStatuses,
  taxBenefits,
  taxLocations,
} from '../../shared/dtos/user-details.interface';
import { ptPostalCodeRegex } from '../../shared/dtos/validators/postal-code.validator';

import { yupResolver } from '@hookform/resolvers/yup';
import useNotifications from '../../redux/notifications/notifications.hooks';
import FormDatePicker from '../forms/form-date-picker';
import FromSelect from '../forms/form-select';
import FormTextField from '../forms/form-text-field';
import TabContainer from '../tabs/tab-container';

interface TaxDetailsFormProps {
  items: TaxData[];
  onAdd: (data: TaxData) => void;
  onUpdate: (previous: TaxData, data: TaxData) => void;
  onRemove: (data: TaxData) => void;

  disabled?: boolean;
}

interface TaxDetailsTabProps {
  item?: TaxData;
  onAdd: (data: TaxData) => void;
  onUpdate: (previous: TaxData, data: TaxData) => void;
  onRemove: (data: TaxData) => void;

  disabled?: boolean;
}

const maritalStatusOptions = Object.keys(maritalStatuses).map((i) => ({
  label: maritalStatuses[i as MaritalStatus],
  value: i,
}));
const taxLocationOptions = Object.keys(taxLocations).map((i) => ({ label: taxLocations[i as TaxLocation], value: i }));
const taxBenefitOptions = Object.keys(taxBenefits).map((i) => ({
  label: taxBenefits[i as TaxBenefitType],
  value: i,
}));

const schema = yup
  .object({
    startedAt: yup.date().required(),
    disabilityDegree: yup
      .mixed<DisabilityDegrees>()
      .oneOf([...Object.values(DisabilityDegrees)])
      .required(),
    maritalStatus: yup
      .mixed<MaritalStatus>()
      .oneOf([...Object.values(MaritalStatus)])
      .required(),
    totalChildCount: yup.number().required().integer().min(0).max(10),
    babyChildCount: yup
      .number()
      .required()
      .integer()
      .min(0)
      .max(10)
      .when('totalChildCount', ([totalChildCount], schema) =>
        schema.max(totalChildCount, 'Valor superior ao número total de filhos'),
      ),
    infantChildCount: yup
      .number()
      .required()
      .integer()
      .min(0)
      .max(10)
      .when('totalChildCount', ([totalChildCount], schema) =>
        schema.max(totalChildCount, 'Valor superior ao número total de filhos'),
      ),
    disabledOver60ChildCount: yup
      .number()
      .required()
      .integer()
      .min(0)
      .max(10)
      .when('totalChildCount', ([totalChildCount], schema) =>
        schema.max(totalChildCount, 'Valor superior ao número total de filhos'),
      ),
    disabledOver90ChildCount: yup
      .number()
      .required()
      .integer()
      .min(0)
      .max(10)
      .when('totalChildCount', ([totalChildCount], schema) =>
        schema.max(totalChildCount, 'Valor superior ao número total de filhos'),
      ),
    postalCode: yup.string().required().matches(ptPostalCodeRegex, 'Tem que ser um código postal válido'),
    taxLocation: yup
      .string()
      .oneOf([...Object.values(TaxLocation)])
      .required(),
    taxBenefit: yup
      .object({
        startedAt: yup.date().default(new Date()).required(),
        type: yup
          .mixed<TaxBenefitType>()
          .oneOf([...Object.values(TaxBenefitType)])
          .required(),
      })
      .optional(),
    paymentsOnAccount: yup
      .object({
        jul: yup.number().required(),
        set: yup.number().required(),
        dec: yup.number().required(),
      })
      .default({ jul: 0, set: 0, dec: 0 })
      .required(),
  })
  .required();

function TaxDetailsTab(props: TaxDetailsTabProps) {
  const defaultValues: DefaultValues<TaxData> = {
    taxLocation: TaxLocation.MAINLAND,
    maritalStatus: MaritalStatus.SINGLE,
    totalChildCount: 0,
    babyChildCount: 0,
    infantChildCount: 0,
    disabledOver60ChildCount: 0,
    disabledOver90ChildCount: 0,
    disabilityDegree: DisabilityDegrees.NONE,
    postalCode: '',
    taxBenefit: undefined,
  };

  const { item, onAdd, onRemove, onUpdate, disabled } = props;
  const methods = useForm<TaxData>({
    defaultValues: item ? { ...item } : defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, watch, trigger, setValue, reset, formState } = methods;
  const watchTaxLocation = watch('taxLocation');
  const watchTaxBenefit = watch('taxBenefit.type');

  useEffect(() => {
    if (formState.isSubmitSuccessful && !item) {
      reset();
    }
  }, [formState, item, reset]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'totalChildCount' && formState.isSubmitted) {
        trigger(['babyChildCount', 'infantChildCount', 'disabledOver60ChildCount', 'disabledOver90ChildCount']);
      }

      if (name === 'taxLocation' && value[name] === 'eu') {
        setValue('postalCode', '');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger, setValue, formState]);

  const handleFormSubmit = (data: TaxData) => {
    const details = {
      ...data,
      taxBenefit: {
        startedAt: data.taxBenefit?.startedAt ?? new Date(),
        type: data.taxBenefit?.type ?? TaxBenefitType.NONE,
      },
    };
    if (!item) {
      onAdd(details);
    } else {
      onUpdate(item, details);
    }
  };

  const handleRemove = () => {
    if (item) {
      onRemove(item);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item sm={4} xs={12}>
            Dados válidos a partir de que ano?
          </Grid>
          <Grid item sm={8} xs={12}>
            <FormDatePicker name="startedAt" label="Selecciona o ano" disabled={!!item} fullWidth yearOnly />
          </Grid>

          <Grid item sm={4} xs={12}>
            Em que zona resides? Indica o teu código postal, caso mores em Portugal.
          </Grid>
          <Grid item sm={4} xs={12}>
            <FromSelect fullWidth name="taxLocation" items={taxLocationOptions} defaultValue={TaxLocation.MAINLAND} />
          </Grid>

          <Grid item sm={4} xs={12}>
            <FormTextField
              name="postalCode"
              label="Código Postal"
              disabled={watchTaxLocation === TaxLocation.EU}
              fullWidth
              defaultValue=""
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            Tens algum grau de deficiência?
          </Grid>
          <Grid item sm={8} xs={12}>
            <FromSelect
              fullWidth
              name="disabilityDegree"
              items={[
                { label: 'Não', value: DisabilityDegrees.NONE },
                { label: 'Sim, superior a 60%', value: DisabilityDegrees.OVER60 },
                { label: 'Sim, superior a 90%', value: DisabilityDegrees.OVER90 },
              ]}
              defaultValue={DisabilityDegrees.NONE}
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            Qual é o teu Estado Civil?
          </Grid>
          <Grid item sm={8} xs={12}>
            <FromSelect
              fullWidth
              name="maritalStatus"
              items={maritalStatusOptions}
              defaultValue={MaritalStatus.SINGLE}
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            Tens filhos?
          </Grid>
          <Grid sm={8} xs={12} item>
            <Grid container spacing={2}>
              <Grid item sm={2} xs={12}>
                <FromSelect
                  label="Quantos filhos tens?"
                  fullWidth
                  name="totalChildCount"
                  items={[
                    { label: '0', value: 0 },
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 },
                    { label: '5', value: 5 },
                    { label: '6', value: 6 },
                    { label: '7', value: 7 },
                    { label: '8', value: 8 },
                    { label: '9', value: 9 },
                    { label: '10', value: 10 },
                  ]}
                  defaultValue={0}
                />
              </Grid>
              <Grid item sm={2} xs={12}>
                <FromSelect
                  label="Menores de 3 anos?"
                  fullWidth
                  name="babyChildCount"
                  items={[
                    { label: '0', value: 0 },
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 },
                    { label: '5', value: 5 },
                    { label: '6', value: 6 },
                    { label: '7', value: 7 },
                    { label: '8', value: 8 },
                    { label: '9', value: 9 },
                    { label: '10', value: 10 },
                  ]}
                  defaultValue={0}
                />
              </Grid>
              <Grid item sm={2} xs={12}>
                <FromSelect
                  label="Maiores de 3 e menores de 6 anos?"
                  fullWidth
                  name="infantChildCount"
                  items={[
                    { label: '0', value: 0 },
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 },
                    { label: '5', value: 5 },
                    { label: '6', value: 6 },
                    { label: '7', value: 7 },
                    { label: '8', value: 8 },
                    { label: '9', value: 9 },
                    { label: '10', value: 10 },
                  ]}
                  defaultValue={0}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FromSelect
                  label="Com deficiência entre 60% e 90%?"
                  fullWidth
                  name="disabledOver60ChildCount"
                  items={[
                    { label: '0', value: 0 },
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 },
                    { label: '5', value: 5 },
                    { label: '6', value: 6 },
                    { label: '7', value: 7 },
                    { label: '8', value: 8 },
                    { label: '9', value: 9 },
                    { label: '10', value: 10 },
                  ]}
                  defaultValue={0}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FromSelect
                  label="Com deficiência superior a 90%?"
                  fullWidth
                  name="disabledOver90ChildCount"
                  items={[
                    { label: '0', value: 0 },
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 },
                    { label: '5', value: 5 },
                    { label: '6', value: 6 },
                    { label: '7', value: 7 },
                    { label: '8', value: 8 },
                    { label: '9', value: 9 },
                    { label: '10', value: 10 },
                  ]}
                  defaultValue={0}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            Tens algum benefício fiscal?
          </Grid>
          <Grid item sm={5} xs={12}>
            <FromSelect
              fullWidth
              name="taxBenefit.type"
              items={taxBenefitOptions}
              defaultValue={taxBenefitOptions[0].value}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormDatePicker
              name="taxBenefit.startedAt"
              label="Selecciona a data de inicio"
              disabled={watchTaxBenefit === TaxBenefitType.NONE}
              fullWidth
              defaultValue={new Date()}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            Fizeste pagamentos por conta?
          </Grid>
          <Grid item sm={8} xs={12} container spacing={2}>
            <Grid item sm={4} xs={12}>
              <FormTextField name="paymentsOnAccount.jul" label="Em Julho" fullWidth defaultValue={0} />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FormTextField name="paymentsOnAccount.set" label="Em Setembro" fullWidth defaultValue={0} />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FormTextField name="paymentsOnAccount.dec" label="Em Dezembro" fullWidth defaultValue={0} />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" gridGap={20}>
              <Button type="submit" variant="contained" color="primary" disabled={disabled || formState.isSubmitting}>
                {item ? 'Actualizar' : 'Adicionar'}
              </Button>
              {item && (
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={disabled || formState.isSubmitting}
                  onClick={handleRemove}
                >
                  Remover
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function TaxDetailsForm(props: TaxDetailsFormProps) {
  const [selection, setSelection] = useState<number>(0);
  const { items, onAdd, onUpdate, onRemove, disabled } = props;
  const { show } = useNotifications();

  const tabs = [{ label: 'Novo' }, ...items.map((item) => ({ label: item.startedAt.getFullYear().toString() }))];

  const getExisting = (startedAt: Date): TaxData | undefined => {
    return items.find((item) => item.startedAt.getFullYear() === startedAt.getFullYear());
  };

  const handleAdd = (data: TaxData) => {
    const repeated = getExisting(data.startedAt);
    if (repeated) {
      show({ message: 'Ano já existente. Por favor, edita esse ou cria um novo.', severity: 'error' });
    } else {
      onAdd(data);
    }
  };

  const handleUpdate = (previous: TaxData, data: TaxData) => {
    const repeated = getExisting(data.startedAt);

    if (repeated !== previous) {
      // Do something;
    } else {
      onUpdate(previous, data);
    }
  };

  const handleRemove = (data: TaxData) => {
    setSelection(0);
    onRemove(data);
  };

  const handleSelectionChange = (newSelection: number) => {
    setSelection(newSelection);
  };

  return (
    <TabContainer
      id="tax-details"
      tabs={tabs}
      variant="scrollable"
      selection={selection}
      onSelectionChange={handleSelectionChange}
    >
      {[
        <TaxDetailsTab
          key="new"
          disabled={disabled}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onRemove={handleRemove}
        />,
        ...items.map((item, index) => (
          <TaxDetailsTab
            key={index}
            item={item}
            disabled={disabled}
            onAdd={handleAdd}
            onUpdate={handleUpdate}
            onRemove={handleRemove}
          />
        )),
      ]}
    </TabContainer>
  );
}
