import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AppTitle from '../../components/app-title';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1px 8px',
  },
  version: {
    color: '#FFFFFF',
    backgroundColor: '#FF672F',
    borderRadius: '50px',
    width: '60px',
    height: '16px',
    fontSize: '8px',
    textAlign: 'center',
    lineHeight: '16px',
  },
}));

export default function NavAppTitle() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <AppTitle alignment="left" />
        </Grid>
        <Grid item>
          <div className={classes.version}>V1.01</div>
        </Grid>
      </Grid>
    </div>
  );
}
