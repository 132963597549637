import * as yup from 'yup';

import {
  Business,
  BusinessIncomeType,
  BusinessTaxRegime,
  SocialSecurityFraming,
  businessIncomes,
  businessSocialSecurityFramings,
  businessTaxRegimes,
} from '../../shared/dtos/user-details.interface';
import FromSelect, { FormSelectCategory, FormSelectItem } from '../forms/form-select';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { DataTableHeaderDefinition } from '../data/data-table.interfaces';
import DataTableWithForm from '../forms/data-table-with-form';
import FormCheckbox from '../forms/form-checkbox';
import FormDatePicker from '../forms/form-date-picker';
import FormTextField from '../forms/form-text-field';

interface BusinessDetailsFormProps {
  items: Business[];
  onSubmit: (data: Business) => void;
  onDelete: (data: Business) => void;

  disabled?: boolean;
}

const serviceIncomes = Object.keys(businessIncomes)
  .filter((i) => i.startsWith('prof'))
  .map((i) => ({ label: businessIncomes[i as BusinessIncomeType], value: i }));

const agroIncomes = Object.keys(businessIncomes)
  .filter((i) => i.startsWith('agr'))
  .map((i) => ({ label: businessIncomes[i as BusinessIncomeType], value: i }));

const incomeTypes: FormSelectCategory[] = [
  {
    title: 'Rendimentos profissionais, comerciais ou industriais',
    items: serviceIncomes,
  },
  {
    title: 'Rendimentos agrícolas, silvícolas e pecuários',
    items: agroIncomes,
  },
];

const taxRegimes: FormSelectItem[] = Object.keys(businessTaxRegimes).map((i) => ({
  label: businessTaxRegimes[i as BusinessTaxRegime],
  value: i,
}));

const ssFramings: FormSelectItem[] = Object.keys(businessSocialSecurityFramings).map((i) => ({
  label: businessSocialSecurityFramings[i as SocialSecurityFraming],
  value: i,
}));

const headers: DataTableHeaderDefinition<Business>[] = [
  {
    id: 'startedAt',
    name: 'Data de início',
    align: 'left',
    format: (item) => format(item.startedAt, 'yyyy-MM-dd'),
    span: 3,
  },
  {
    id: 'endedAt',
    name: 'Data de fecho',
    format: (item) => (item.endedAt ? format(item.endedAt, 'yyyy-MM-dd') : '-'),
    align: 'left',
    span: 3,
  },
  {
    id: 'mainIncomeType',
    name: 'Fonte de Rendimento',
    format: (item) => businessIncomes[item.mainIncomeType],
    align: 'left',
  },
  {
    id: 'mainCirs',
    name: 'CIRS ou CAE',
    format: (item) => item.mainCirs ?? '-',
    align: 'center',
    span: 2,
  },
  {
    id: 'socialSecurityFraming',
    name: 'Registo',
    format: (item) => businessSocialSecurityFramings[item.socialSecurityFraming],
    align: 'left',
    span: 3,
  },
  {
    id: 'taxRegime',
    name: 'Regime IVA',
    format: (item) => businessTaxRegimes[item.taxRegime],
    align: 'center',
    span: 2,
  },
  {
    id: 'contributesToCpas',
    name: 'Contribuição CPAS',
    format: (item) => (item.contributesToCpas ? 'Sim' : 'Não'),
    align: 'center',
    span: 1,
  },
];

const schema = yup
  .object({
    startedAt: yup.date().required(),
    endedAt: yup
      .date()
      .optional()
      .when(
        'startedAt',
        (startedAt, schema) =>
          startedAt && schema.min(startedAt, 'Tem de ser posterior à data de início de actividade'),
      ),
    mainIncomeType: yup
      .mixed<BusinessIncomeType>()
      .oneOf([...Object.values(BusinessIncomeType)])
      .required(),
    taxRegime: yup
      .mixed<BusinessTaxRegime>()
      .oneOf([...Object.values(BusinessTaxRegime)])
      .required(),
    mainCirs: yup
      .number()
      .transform((value, original) => (!original?.length ? undefined : value))
      .required()
      .positive(),
    socialSecurityFraming: yup
      .mixed<SocialSecurityFraming>()
      .oneOf([...Object.values(SocialSecurityFraming)])
      .required(),
    contributesToCpas: yup.boolean().required(),
  })
  .required();

export default function BusinessDetailsForm(props: BusinessDetailsFormProps) {
  const { items, onSubmit, onDelete, disabled } = props;

  return (
    <DataTableWithForm
      headers={headers}
      items={items}
      onSubmit={onSubmit}
      onDelete={onDelete}
      emptyMessage="Sem informação de actividade"
      disabled={disabled}
      resolver={yupResolver(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={2}>
          <FormDatePicker name="startedAt" label="Data de início" fullWidth />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <FormDatePicker name="endedAt" label="Data de fecho" clearable fullWidth />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FromSelect
            label="Principal fonte de rendimento"
            fullWidth
            name="mainIncomeType"
            items={incomeTypes}
            defaultValue={incomeTypes[0].items[0].value}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <FormTextField name="mainCirs" label="CIRS ou CAE" defaultValue={''} fullWidth />
        </Grid>
        <Grid item xs={12} md={6} lg={6}></Grid>
        <Grid item xs={12} md={6} lg={2}>
          <FromSelect
            label="Registo"
            fullWidth
            name="socialSecurityFraming"
            items={ssFramings}
            defaultValue={ssFramings[0].value}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <FromSelect
            label="Regime de IVA"
            fullWidth
            name="taxRegime"
            items={taxRegimes}
            defaultValue={taxRegimes[0].value}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <FormCheckbox name="contributesToCpas" label="Contribuições para o CPAS?" defaultValue={false} />
        </Grid>
      </Grid>
    </DataTableWithForm>
  );
}
