import { Expense, ExpenseParams } from '../../shared/dtos/expenses.interface';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ExpensesState } from './expenses.interface';

const initialState: ExpensesState = {
  isImportFormOpen: false,
  isUpdateFormOpen: false,
  params: { offset: 0, limit: 10 },
};

export const expensesSlice = createSlice({
  name: 'expenses',
  initialState,
  reducers: {
    openImportForm(state) {
      state.isImportFormOpen = true;
    },
    closeImportForm(state) {
      state.isImportFormOpen = false;
    },
    openUpdateForm(state, { payload }: PayloadAction<Expense | undefined | null>) {
      state.isUpdateFormOpen = true;
      state.expenseToUpdate = payload;
    },
    closeUpdateForm(state) {
      state.isUpdateFormOpen = false;
      state.expenseToUpdate = null;
    },
    setParams(state, { payload }: PayloadAction<ExpenseParams>) {
      state.params = payload;
    },
    resetParams(state) {
      state.params = initialState.params;
    },
  },
});

export default expensesSlice.reducer;
