import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { setForbidden, setLoggedIn } from './authentication/authentication.slice';

const baseQueryWithRedir: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  Record<string, unknown>,
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  const baseQuery = fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL, credentials: 'include' });
  const result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    if (result.error.status === 401) {
      api.dispatch(setLoggedIn(false));
    } else if (result.error.status === 403) {
      api.dispatch(setForbidden(true));
    }
  }

  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithRedir,
  tagTypes: [
    'Profile',
    'UserDetails',
    'PaymentMethods',
    'Expenses',
    'Entities',
    'Invoices',
    'Taxes',
    'Integrations',
    'Dashboard',
    'PaymentInvoices',
    'Partners',
    'SocialSecuritySettings',
  ],
  endpoints: () => ({}),
});
