import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { PaymentInvoicesParams } from '../../shared/dtos/payment-invoices.interface';
import { PaymentInvoicesState } from './payment-invoices.interface';

const initialState: PaymentInvoicesState = {
  params: { offset: 0, limit: 12 },
};

export const paymentInvoicesSlice = createSlice({
  name: 'payment-invoices',
  initialState,
  reducers: {
    setParams(state, { payload }: PayloadAction<PaymentInvoicesParams>) {
      state.params = payload;
    },
    resetParams(state) {
      state.params = initialState.params;
    },
  },
});

export default paymentInvoicesSlice.reducer;
