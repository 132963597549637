import { Employment } from '../../shared/dtos/user-details.interface';
import EmploymentDetailsForm from '../form-employment-details/employment-details.form';
import { FullTaxFormStepProps } from './tax-form.interfaces';
import { useImperativeHandle } from 'react';
import useTaxForm from '../../redux/tax-form/tax-form.hooks';

export default function EmploymentDetailsStep(props: FullTaxFormStepProps) {
  const { mRef } = props;
  const { setEmploymentHistory: setEmploymentDetails, employmentHistory: employmentDetails } = useTaxForm();

  useImperativeHandle(mRef, () => ({
    submit: (): Promise<string | null | undefined> => {
      return Promise.resolve(null);
    },
  }));

  const handleSubmit = (data: Employment) => {
    setEmploymentDetails([...employmentDetails, data].sort((a, b) => b.startedAt.getTime() - a.startedAt.getTime()));
  };

  const handleDelete = (data: Employment) => {
    setEmploymentDetails(employmentDetails.filter((i) => i !== data));
  };

  return (
    <EmploymentDetailsForm
      items={employmentDetails}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
    ></EmploymentDetailsForm>
  );
}
