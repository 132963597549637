import { LoadingContainer } from '../../components/loading-container/loading-container';
import { useEffect } from 'react';
import useNavigation from '../../navigation/nav.hook';
import useNotifications from '../../redux/notifications/notifications.hooks';
import { useQuery } from '../../common/hooks/query.hook';
import { useVerifyMutation } from '../../redux/authentication/authentication.api';

export default function VerifyPage() {
  const { show } = useNotifications();
  const { navToLogin } = useNavigation();
  const [verify] = useVerifyMutation();
  const params = useQuery();

  useEffect(() => {
    const tryVerify = async () => {
      const email = params.get('email');
      const token = params.get('token');

      try {
        if (email && token) {
          await verify({ email, token }).unwrap();

          show({
            message: 'Conta activada com sucesso',
            severity: 'success',
            duration: 5000,
          });
        }
      } catch (e) {
        show({
          message: 'Ocorreu um problema ao activar a conta',
          severity: 'error',
          duration: 5000,
        });
      }

      navToLogin();
    };

    tryVerify();
  }, [navToLogin, params, show, verify]);

  return <LoadingContainer isLoading={true} children={[]}></LoadingContainer>;
}
