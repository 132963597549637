import { Business } from '../../shared/dtos/user-details.interface';
import BusinessDetailsForm from '../form-business-details/business-details.form';
import { FullTaxFormStepProps } from './tax-form.interfaces';
import { useImperativeHandle } from 'react';
import useTaxForm from '../../redux/tax-form/tax-form.hooks';

export default function BusinessDetailsStep(props: FullTaxFormStepProps) {
  const { mRef } = props;
  const { setBusinessHistory: setBusinessDetails, businessHistory: businessDetails } = useTaxForm();

  useImperativeHandle(mRef, () => ({
    submit: (): Promise<string | null | undefined> => {
      if (!businessDetails.length) {
        return Promise.resolve('Tem que indicar quando iniciou actividade');
      }
      return Promise.resolve(null);
    },
  }));

  const handleSubmit = (data: Business) => {
    setBusinessDetails([...businessDetails, data].sort((a, b) => b.startedAt.getTime() - a.startedAt.getTime()));
  };

  const handleDelete = (data: Business) => {
    setBusinessDetails(businessDetails.filter((i) => i !== data));
  };

  return <BusinessDetailsForm items={businessDetails} onSubmit={handleSubmit} onDelete={handleDelete} />;
}
