import { setLocale } from 'yup';

setLocale({
  mixed: {
    notType: 'Valor inválido',
    default: 'Valor inválido',
    required: 'Campo obrigatório',
  },
  number: {
    // eslint-disable-next-line no-template-curly-in-string
    min: 'Deve ser maior que ${min}',
    // eslint-disable-next-line no-template-curly-in-string
    max: 'Deve ser menor que ${max}',
    integer: 'Deve ser um número inteiro',
    positive: 'Deve ser um número positivo',
    negative: 'Deve ser um número negativo',
  },
  date: {
    // eslint-disable-next-line no-template-curly-in-string
    max: 'Deve ser uma data menor que ${max}',
    // eslint-disable-next-line no-template-curly-in-string
    min: 'Deve ser uma data maior que ${min}',
  },
  string: {
    // eslint-disable-next-line no-template-curly-in-string
    length: 'Deve ter ${length} caracteres',
    email: 'Deve ser um e-mail válido',
    // eslint-disable-next-line no-template-curly-in-string
    min: 'Deve ter pelo menos ${min} caracteres',
  },
});
