import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActivityProviderState } from './activity-provider.interfaces';

const initialState: ActivityProviderState = {
  isImportFormOpen: false,
};

export const activityProviderSlice = createSlice({
  name: 'activityProvider',
  initialState,
  reducers: {
    openImportForm(state, { payload }: PayloadAction<any>) {
      state.isImportFormOpen = true;
      state.formData = payload;
    },
    closeImportForm(state) {
      state.isImportFormOpen = false;
      state.formData = undefined;
    },
  },
});

export default activityProviderSlice.reducer;
