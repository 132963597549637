import './yupconfig';

import * as ReactDOM from 'react-dom';

import App from './app';
import { StrictMode } from 'react';

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);
