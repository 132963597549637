import * as yup from 'yup';

import { Box, Typography } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import AppTitle from '../../components/app-title';
import FormTextField from '../../components/forms/form-text-field';
import LoadingButton from '../../components/forms/loading-button';
import VBox from '../../components/layout/vbox';
import Hyperlink from '../../components/navigation/hyperlink';
import useNavigation from '../../navigation/nav.hook';
import { ROUTE_LOGIN } from '../../navigation/routes';
import { useTriggerPasswordResetMutation } from '../../redux/authentication/authentication.api';
import useAuthentication from '../../redux/authentication/authentication.hooks';
import useNotifications from '../../redux/notifications/notifications.hooks';
import { TriggerPasswordReset } from '../../shared/dtos/reset-password.interface';

const useStyles = makeStyles((theme) => ({
  grid: {
    height: '100%',
  },
}));

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

export default function ForgotPasswordPage() {
  const classes = useStyles();
  const methods = useForm<TriggerPasswordReset>({ resolver: yupResolver(schema) });
  const { isLoggedIn } = useAuthentication();
  const [triggerPasswordReset, { isLoading }] = useTriggerPasswordResetMutation();
  const { navToHome, navToLogin } = useNavigation();
  const { show } = useNotifications();

  useEffect(() => {
    if (isLoggedIn) {
      navToHome();
    }
  }, [isLoggedIn, navToHome]);

  const onSubmit = async (data: TriggerPasswordReset) => {
    try {
      await triggerPasswordReset(data).unwrap();
      show({
        message: 'Irás receber um e-mail com instruções para recuperar a sua conta',
        severity: 'info',
        duration: 30000,
      });
      navToLogin();
    } catch (e) {
      show({
        message: 'Ocorreu um problema inesperado. Tente novamente mais tarde.',
        severity: 'error',
        duration: 5000,
      });
    }
  };

  return (
    <Box
      width={{ xs: '100%', sm: '100%', md: 376 }}
      height={{ xs: '100%', sm: '100%', md: 'auto' }}
      mx="auto"
      my={{ xs: 'auto', sm: 'auto', md: 0 }}
      paddingTop={{ xs: '28px', sm: '28px', md: '10vh' }}
    >
      <VBox spacing={0} className={classes.grid}>
        <VBox spacing={2}>
          <Box display="flex" justifyContent="center">
            <Box borderRadius={26} paddingX={2.5} paddingY={1.5} bgcolor="secondary.main" boxShadow={1}>
              <AppTitle />
            </Box>
          </Box>
          <Box textAlign="center" padding="20px">
            <Typography variant="h6">Recuperação de Palavra-Passe</Typography>
          </Box>
        </VBox>
        <Box
          height="100%"
          textAlign="center"
          paddingY={2.5}
          paddingX={4.5}
          borderRadius={{ xs: '20px 20px 0 0', sm: '20px 20px 0 0', md: '20px 20px 20px 20px' }}
          boxShadow={{ xs: 1, sm: 1, md: 0 }}
          bgcolor="secondary.main"
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <VBox spacing={4}>
                <FormTextField name="email" type="text" label="E-mail da conta a recuperar" fullWidth />
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  isLoading={methods.formState.isSubmitting || isLoading}
                >
                  Enviar instruções de recuperação
                </LoadingButton>
              </VBox>
            </form>
          </FormProvider>
          <Box marginTop={4.25}>
            <Typography variant="body2" component="p" color="textPrimary">
              Sabes as tuas credenciais? <Hyperlink path={ROUTE_LOGIN}>Faz login</Hyperlink>
            </Typography>
          </Box>
        </Box>
      </VBox>
    </Box>
  );
}
