import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';

import { OpenPortalLoginDialogData } from './portal-login-dialog.interfaces';
import { portalLoginDialogSelector } from './portal-login-dialog.selectors';
import { portalLoginDialogSlice } from './portal-login-dialog.slice';
import { ensurePortalLogin } from './portal-login-dialog.thunks';

function usePortalLoginDialog() {
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector(portalLoginDialogSelector);

  const ensure = useCallback(
    async (data: OpenPortalLoginDialogData) => {
      return dispatch(ensurePortalLogin(data));
    },
    [dispatch],
  );

  const confirm = useCallback(() => {
    return dispatch(portalLoginDialogSlice.actions.confirm());
  }, [dispatch]);

  const cancel = useCallback(() => {
    return dispatch(portalLoginDialogSlice.actions.cancel());
  }, [dispatch]);

  return useMemo(
    () => ({
      isOpen,
      ensure,
      confirm,
      cancel,
    }),
    [isOpen, ensure, confirm, cancel],
  );
}

export default usePortalLoginDialog;
