import { DataTableHeaderDefinition, DataTableRowDefinition, RowAction } from './data-table.interfaces';
import { TableRow } from '@material-ui/core';

import DataTableActions from './data-table-actions';
import { DataTableCell } from './data-table-cell';

interface DataTableRowProps<T> {
  headers: DataTableHeaderDefinition<T>[];
  row: DataTableRowDefinition<T>;
  index: number;
  actions?: RowAction[];

  onRowEdit?: (row: T, index: number) => void;
  onRowDelete?: (row: T, index: number) => void;
}

export default function DataTableRow<T>(props: DataTableRowProps<T>) {
  const { index, row, headers, actions, onRowEdit, onRowDelete } = props;

  const onEdit = actions?.length && row.editable ? onRowEdit : undefined;
  const onDelete = actions?.length && row.deletable ? onRowDelete : undefined;

  return (
    <TableRow hover tabIndex={-1} key={index}>
      {headers.map((header, cellIndex) => (
        <DataTableCell key={cellIndex} rowIndex={index} header={header} row={row} />
      ))}
      {actions?.length && (
        <DataTableActions index={index} actions={actions} row={row.data} onEdit={onEdit} onDelete={onDelete} />
      )}
    </TableRow>
  );
}
