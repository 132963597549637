import { PaymentMethodsState } from './payment-methods.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentMethodType } from '../../shared/dtos/payment-methods.interface';

const initialState: PaymentMethodsState = {
  isOpen: false,
  type: null,
};

export const paymentMethodsSlice = createSlice({
  name: 'payment-methods',
  initialState,
  reducers: {
    openPaymentMethodsDialog: (state, { payload }: PayloadAction<PaymentMethodType>) => {
      state.isOpen = true;
      state.type = payload;
    },
    closePaymentMethodsDialog: (state) => {
      state.isOpen = false;
      state.type = null;
    },
  },
});

export default paymentMethodsSlice.reducer;
