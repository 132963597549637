import { api } from '../api';
import { Promotion } from './promotions.interface';

export const promotionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPromotionById: builder.query<Promotion, string>({
      query: (id: string) => ({
        url: `promotions/${id}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetPromotionByIdQuery, useLazyGetPromotionByIdQuery } = promotionsApi;
