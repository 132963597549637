import store, { RootState } from '../store';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { portalActivitiesProviderApi } from '../activity-provider/activity-provider.api';
import { OpenPortalLoginDialogData } from './portal-login-dialog.interfaces';
import { portalLoginDialogSlice } from './portal-login-dialog.slice';

export const ensurePortalLogin = createAsyncThunk<boolean, OpenPortalLoginDialogData, { state: RootState }>(
  'portalLoginDialog/ensure',
  async (request, { getState, dispatch }): Promise<boolean> => {
    const { action } = request;

    const status = await dispatch(
      portalActivitiesProviderApi.endpoints.portalAuthStatus.initiate(undefined, { forceRefetch: true }),
    ).unwrap();
    if (status.isLoggedIn) {
      return Promise.resolve(true);
    }

    dispatch(portalLoginDialogSlice.actions.open());

    return new Promise<boolean>((resolve) => {
      const unsubscribe = store.subscribe(async () => {
        const { isCancelled, isConfirmed } = getState().confirmationDialog;

        if (isConfirmed) {
          unsubscribe();

          if (action) {
            await dispatch(action);
          }

          resolve(true);
        }

        if (isCancelled) {
          unsubscribe();
          resolve(false);
        }
      });
    });
  },
);
