import { Tab, Tabs, TabsProps } from '@material-ui/core';
import { Theme, createStyles, withStyles } from '@material-ui/core/styles';

import { TabPanel } from './tab-panel';

interface TabContainerProps extends TabsProps {
  id: string;
  tabs: { label: string }[];
  children?: React.ReactNode | React.ReactNode[];

  selection?: number;
  onSelectionChange?: (selection: number) => void;
}

const StyledTabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  }),
)(Tabs);

export default function TabContainer(props: TabContainerProps) {
  const { id, tabs, children, selection, onSelectionChange, ...other } = props;

  const handleChange = (_event: React.ChangeEvent<unknown>, newValue: number) => {
    onSelectionChange?.(newValue);
  };

  const renderTabs = () => {
    if (children && Array.isArray(children)) {
      return children?.map((child, index) => (
        <TabPanel
          id={`${id}-child-${index}`}
          key={`${id}-child-${index}`}
          parentId={`${id}-${index}`}
          value={selection ?? 0}
          index={index}
        >
          {child}
        </TabPanel>
      ));
    }
    return children;
  };

  return (
    <>
      <StyledTabs
        id={id}
        value={selection}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        style={{ overflow: other.orientation === 'vertical' ? 'visible' : 'hidden' }}
        {...other}
      >
        {tabs.map(({ label }, index) => (
          <Tab id={`${id}-${index}`} key={`${id}-${index}`} aria-controls={`${id}-child-${index}`} label={label} />
        ))}
      </StyledTabs>
      {renderTabs()}
    </>
  );
}
