export enum BusinessIncomeType {
  PROF_1 = 'prof_1',
  PROF_2 = 'prof_2',
  PROF_3 = 'prof_3',
  PROF_4 = 'prof_4',
  PROF_5 = 'prof_5',
  PROF_6 = 'prof_6',
  PROF_7 = 'prof_7',
  PROF_8 = 'prof_8',
  PROF_9 = 'prof_9',
  PROF_10 = 'prof_10',
  PROF_11 = 'prof_11',
  PROF_12 = 'prof_12',
  PROF_13 = 'prof_13',
  PROF_14 = 'prof_14',
  PROF_15 = 'prof_15',
  AGR_1 = 'agr_1',
  AGR_2 = 'agr_2',
  AGR_3 = 'agr_3',
  AGR_4 = 'agr_4',
  AGR_5 = 'agr_5',
  AGR_6 = 'agr_6',
  AGR_7 = 'agr_7',
}

export const businessIncomes: { [key in BusinessIncomeType]: string } = {
  prof_1: 'Prestação de serviços - Atividades da tabela do art.º 151 do CIRS',
  prof_2: 'Venda de mercadorias e produtos',
  prof_3: 'Prestação de serviços - Outras atividades (código 1519 da tabela do art.º 151 do CIRS)',
  prof_4: 'Prestação de serviços - Alojamento local (moradia ou apartamento)',
  prof_5: 'Prestação de serviços - Alojamento local | Zonas de contenção (moradia ou apartamento)',
  prof_6: 'Prestação de serviços - Restauração e bebidas',
  prof_7: 'Prestação de serviços - Hotelaria e similares',
  prof_8: 'Direitos de autor (royalties)',
  prof_9: 'Prestação de serviços - Atividades financeiras (códigos CAE iniciados por 64, 65 ou 66)',
  prof_10: 'Subsídios à exploração',
  prof_11: 'Outros subsídios',
  prof_12: 'Mais ou menos valias de incrementos patrimoniais',
  prof_13: 'Rendimentos de capitais imputados a atividade independente',
  prof_14: 'Saldo positivo de rendimentos prediais imputados a atividade independente',
  prof_15: 'Outros não incluídos nos campos anteriores',
  agr_1: 'Vendas de produtos',
  agr_2: 'Prestação de serviços - Atividades agrícolas, silvícolas e pecuários',
  agr_3: 'Vendas silvícolas plurianuais',
  agr_4: 'Subsídios à exploração',
  agr_5: 'Outros subsídios',
  agr_6:
    'Rendimentos de capitais, propriedade industrial, incrementos patrimoniais ou saldo positivo de rendimentos prediais imputados a atividade independente',
  agr_7: 'Outros não incluídos nos campos anteriores',
};

export enum BusinessTaxRegime {
  NORMAL = 'normal',
  REG_ART_53 = 'reg_art_53',
  REG_ART_9 = 'reg_art_9',
}

export const businessTaxRegimes: { [key in BusinessTaxRegime]: string } = {
  normal: 'Normal',
  reg_art_53: 'Art.º 53º',
  reg_art_9: 'Art.º 9º',
};

export const businessSocialSecurityFramings: { [key in SocialSecurityFraming]: string } = {
  'independent-contractor': 'Trabalhador Independente',
  'individual-entrepreneur': 'Empresário em Nome Individual',
};

export interface Business {
  startedAt: Date;
  endedAt?: Date;
  mainIncomeType: BusinessIncomeType;
  taxRegime: BusinessTaxRegime;
  mainCirs: number;
  socialSecurityFraming: SocialSecurityFraming;
  contributesToCpas: boolean;
}

export enum MaritalStatus {
  SINGLE = 'single',
  MARRIED = 'married',
  WIDOWER = 'widower',
  UNION = 'union',
}

export const maritalStatuses: { [key in MaritalStatus]: string } = {
  single: 'Solteiro/a',
  married: 'Casado/a',
  union: 'União de Facto',
  widower: 'Viúvo/a',
};

export enum TaxLocation {
  MAINLAND = 'ml',
  AZORES = 'az',
  MADEIRA = 'md',
  EU = 'eu',
  OTHER = 'ot',
}

export const taxLocations: { [key in TaxLocation]: string } = {
  ml: 'Continente',
  az: 'Açores',
  md: 'Madeira',
  eu: 'União Europeia ou Espaço Económico Europeu',
  ot: 'Outro País',
};

export enum TaxBenefitType {
  NONE = 'none',
  BEN_1 = 'ben_non_resident', // Estatuto do residente não habitual
  BEN_2 = 'ben_regressar', // Programa regressar
  BEN_3 = 'ben_irs_jovem', // IRS jovem
}

export enum SocialSecurityFraming {
  INDEPENDENT = 'independent-contractor',
  ENTERPRENEUR = 'individual-entrepreneur',
}

export enum DisabilityDegrees {
  NONE = 'none',
  OVER60 = 'over60',
  OVER90 = 'over90',
}

export interface TaxBenefit {
  startedAt: Date;
  type: TaxBenefitType;
}

export type TaxBenefitOptions = { [key in TaxBenefitType]: string };

export const taxBenefits: TaxBenefitOptions = {
  none: 'Não',
  ben_non_resident: 'Estatuto do residente não habitual',
  ben_regressar: 'Programa regressar',
  ben_irs_jovem: 'IRS jovem',
};

export interface PaymentsOnAccount {
  jul: number;
  set: number;
  dec: number;
}

export interface TaxData {
  startedAt: Date;
  disabilityDegree: DisabilityDegrees;
  maritalStatus: MaritalStatus;
  totalChildCount: number;
  babyChildCount: number;
  infantChildCount: number;
  disabledOver60ChildCount: number;
  disabledOver90ChildCount: number;
  postalCode: string;
  taxLocation: TaxLocation;
  taxBenefit?: TaxBenefit;
  paymentsOnAccount: PaymentsOnAccount;
}

export interface Employment {
  startedAt: Date;
  endedAt?: Date;
  baseSalary: number;
  extraSalary?: number;
  retainedIncomeTax?: number;
}

export interface UserDetails {
  taxDataHistory?: TaxData[];
  businessHistory?: Business[];
  employmentHistory?: Employment[];
}

export interface EconomicActivity {
  id: string;
  name: string;
}
