import { ConfirmationDialogState, OpenConfirmationDialog } from './confirmation-dialog.interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: ConfirmationDialogState = {
  isOpen: false,
  isCancelled: false,
  isConfirmed: false,
};

export const confirmationDialogSlice = createSlice({
  name: 'confirmationDialog',
  initialState,
  reducers: {
    open: (state, { payload }: PayloadAction<OpenConfirmationDialog>) => {
      state.isOpen = true;
      state.isConfirmed = false;
      state.isCancelled = false;
      state.title = payload.title;
      state.message = payload.message;
    },
    confirm: (state) => {
      state.isOpen = false;
      state.isConfirmed = true;
      state.isCancelled = false;
    },
    cancel: (state) => {
      state.isOpen = false;
      state.isConfirmed = false;
      state.isCancelled = true;
    },
  },
});

export default confirmationDialogSlice.reducer;
