import * as yup from 'yup';

import { Box, Typography } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useCheckResetPasswordQuery, useResetPasswordMutation } from '../../redux/authentication/authentication.api';

import { yupResolver } from '@hookform/resolvers/yup';
import { Navigate } from 'react-router-dom';
import { useQuery } from '../../common/hooks/query.hook';
import AppTitle from '../../components/app-title';
import FormTextField from '../../components/forms/form-text-field';
import LoadingButton from '../../components/forms/loading-button';
import VBox from '../../components/layout/vbox';
import useNavigation from '../../navigation/nav.hook';
import useNotifications from '../../redux/notifications/notifications.hooks';
import { passwordRegex } from '../../shared/dtos/validators/password.validator';
import { PasswordChange } from './password-reset.interfaces';

const schema = yup
  .object({
    password: yup.string().required().matches(passwordRegex, 'A password deve ter pelo menos 8 caracteres e 1 número'),
    passwordConfirm: yup
      .string()
      .required()
      .oneOf([yup.ref('password')], 'Confirma a nova password'),
  })
  .required();

export default function PasswordResetPage() {
  const methods = useForm<PasswordChange>({ resolver: yupResolver(schema) });
  const [resetPassword, { isLoading: isChangeLoading }] = useResetPasswordMutation();
  const { navToLogin } = useNavigation();
  const { show } = useNotifications();
  const params = useQuery();
  const email = params.get('email') ?? '';
  const token = params.get('token') ?? '';
  const { error, isLoading: isCheckLoading } = useCheckResetPasswordQuery(
    { email, token },
    { refetchOnMountOrArgChange: true },
  );

  const onSubmit = async ({ password }: PasswordChange) => {
    try {
      await resetPassword({ token, email, password }).unwrap();
      show({
        message: 'Password alterada com sucesso',
        severity: 'info',
        duration: 30000,
      });
      navToLogin();
    } catch (e) {
      show({
        message: 'Ocorreu um problema inesperado. Tente novamente mais tarde.',
        severity: 'error',
        duration: 5000,
      });
    }
  };

  if (isCheckLoading) {
    return null;
  }

  if (error) {
    return <Navigate to="/" />;
  }

  return (
    <Box
      width={{ xs: '100%', sm: '100%', md: 376 }}
      height={{ xs: '100%', sm: '100%', md: 'auto' }}
      mx="auto"
      my={{ xs: 'auto', sm: 'auto', md: 0 }}
      paddingTop={{ xs: '28px', sm: '28px', md: '10vh' }}
    >
      <VBox spacing={0}>
        <VBox spacing={2}>
          <Box display="flex" justifyContent="center">
            <Box borderRadius={26} paddingX={2.5} paddingY={1.5} bgcolor="secondary.main" boxShadow={1}>
              <AppTitle />
            </Box>
          </Box>
          <Box textAlign="center" padding="20px">
            <Typography variant="h6">Recuperação de Palavra-Passe</Typography>
          </Box>
        </VBox>
        <Box
          height="100%"
          textAlign="center"
          paddingY={2.5}
          paddingX={4.5}
          borderRadius={{ xs: '20px 20px 0 0', sm: '20px 20px 0 0', md: '20px 20px 20px 20px' }}
          boxShadow={{ xs: 1, sm: 1, md: 0 }}
          bgcolor="secondary.main"
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <VBox spacing={2}>
                <FormTextField name="password" type="password" label="Palavra-passe" fullWidth />
                <FormTextField name="passwordConfirm" type="password" label="Confirmação da Palavra-passe" fullWidth />
                <Box marginY={2}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    isLoading={methods.formState.isSubmitting || isChangeLoading}
                  >
                    Alterar a Palavra-Passe
                  </LoadingButton>
                </Box>
              </VBox>
            </form>
          </FormProvider>
        </Box>
      </VBox>
    </Box>
  );
}
