import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface AppTitleProps {
  alignment?: 'left' | 'center';
}

const useStyles = makeStyles({
  root: ({ alignment }: { alignment?: 'left' | 'center' }) => ({
    textAlign: alignment || 'center',
  }),
});

export default function AppTitle({ alignment }: AppTitleProps) {
  const classes = useStyles({ alignment });
  return (
    <Typography className={classes.root} variant="h1" component="h1" color="primary">
      Jupiter{' '}
      <Typography variant="subtitle1" component="span" color="textPrimary">
        App
      </Typography>
    </Typography>
  );
}
