import { Button, Grid } from '@material-ui/core';
import { DefaultValues, FieldValues, FormProvider, Resolver, UseFormReturn, useForm } from 'react-hook-form';

import AddIcon from '@material-ui/icons/Add';
import DataTable from '../data/data-table';
import { DataTableHeaderDefinition } from '../data/data-table.interfaces';
import VBox from '../layout/vbox';

export interface DataTableWithFormProps<T extends FieldValues> {
  children?: React.ReactElement[] | React.ReactElement;

  render?: (form: UseFormReturn<T, object>) => React.ReactElement;

  headers: DataTableHeaderDefinition<T>[];
  items: T[];
  onSubmit: (data: T) => void;
  onDelete: (data: T) => void;

  emptyMessage?: string;
  disabled?: boolean;

  defaultValues?: DefaultValues<T>;
  resolver?: Resolver<T, object>;
}

export default function DataTableWithForm<T extends FieldValues>(props: DataTableWithFormProps<T>) {
  const { headers, children, items, onSubmit, onDelete, emptyMessage, render, disabled, defaultValues, resolver } =
    props;
  const methods = useForm<T>({ defaultValues, resolver });

  const { formState, handleSubmit, reset } = methods;

  const handleFormSubmit = (data: T) => {
    onSubmit(data as T);
    reset(defaultValues);
  };

  const handleDelete = (item: T) => {
    onDelete(item);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <VBox spacing={5}>
          <DataTable
            headers={headers}
            items={items.map((item) => ({
              deletable: true,
              data: item,
            }))}
            onRowDelete={handleDelete}
            actions={['delete']}
            emptyHeight="53px"
            onEmpty={() => emptyMessage}
          />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} lg={11}>
              {render ? render(methods) : children ? children : null}
            </Grid>
            <Grid item container xs={12} lg={1} justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                aria-label="adicionar"
                type="submit"
                disabled={disabled || formState.isSubmitting}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
        </VBox>
      </form>
    </FormProvider>
  );
}
