import { InputAdornment, TextField } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

interface FormTextFieldProps extends UseControllerProps {
  type?: string;
  label?: string;
  helperText?: string | null;
  fullWidth?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '14px',
  },
}));

function Adornment({ hasError, isDirty, hasFocus }: { hasError: boolean; isDirty: boolean; hasFocus: boolean }) {
  const classes = useStyles();

  if (!hasError && !isDirty) {
    return null;
  }

  if (hasError) {
    return (
      <InputAdornment position="end">
        <CancelIcon className={classes.icon} color="error" />
      </InputAdornment>
    );
  }

  return (
    <InputAdornment position="end">
      <CheckCircleIcon className={classes.icon} color={hasFocus ? 'primary' : 'action'} />
    </InputAdornment>
  );
}

export default function FormTextField(props: FormTextFieldProps) {
  const { type, label, fullWidth, autoFocus, disabled, helperText, onChange, ...other } = props;
  const {
    control,
    formState: { errors, dirtyFields, isSubmitting },
  } = useFormContext();
  const [hasFocus, setHasFocus] = useState(false);

  const path = useMemo(() => other.name?.split('.'), [other]);
  const error = useMemo(() => errors[path[Math.max(path.length - 1, 0)]]?.message as string, [errors, path]);
  const isDirty = useMemo(() => !!path?.reduce((o, i) => o?.[i], dirtyFields), [dirtyFields, path]);
  const helpTextToShow = useMemo(() => error || helperText || '', [error, helperText]);

  const handleFocus = () => {
    setHasFocus(true);
  };

  const handleBlur = () => {
    setHasFocus(false);
  };

  return (
    <Controller
      {...other}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <TextField
          {...field}
          inputRef={ref}
          label={label}
          type={type ?? 'text'}
          fullWidth={fullWidth}
          autoFocus={autoFocus}
          error={!!error}
          helperText={helpTextToShow}
          InputProps={{
            endAdornment: <Adornment hasError={!!error} hasFocus={hasFocus} isDirty={isDirty} />,
          }}
          disabled={isSubmitting || disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={(event) => {
            field.onChange(event.target.value);
            if (onChange) {
              onChange(event as React.ChangeEvent<HTMLInputElement>);
            }
          }}
        />
      )}
    />
  );
}
