import { Box } from '@material-ui/core';

interface TabPanelProps {
  id: string;
  parentId: string;
  value: number;
  index: number;
  children: React.ReactNode;
}

export function TabPanel(props: TabPanelProps) {
  const { id, parentId, children, value, index, ...other } = props;

  return (
    <div id={id} role="tabpanel" hidden={value !== index} aria-labelledby={parentId} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
