import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { InvoiceParams } from '../../shared/dtos/invoices.interface';
import { InvoicesState } from './invoices.interfaces';

const initialState: InvoicesState = {
  isImportFormOpen: false,
  params: { offset: 0, limit: 10 },
};

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    openImportForm(state) {
      state.isImportFormOpen = true;
    },
    closeImportForm(state) {
      state.isImportFormOpen = false;
    },
    setParams(state, { payload }: PayloadAction<InvoiceParams>) {
      state.params = payload;
    },
    resetParams(state) {
      state.params = initialState.params;
    },
  },
});

export default invoicesSlice.reducer;
