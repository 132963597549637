import { useAppDispatch, useAppSelector } from '../hooks';
import { useCallback, useMemo } from 'react';

import { authSelector } from './authentication.selectors';
import { authenticationSlice } from './authentication.slice';

function useAuthentication() {
  const dispatch = useAppDispatch();
  const { isLoggedIn, isForbidden, isAccountCloseConfirmFormOpen } = useAppSelector(authSelector);

  const setLoggedIn = useCallback(
    (loggedIn: boolean) => {
      return dispatch(authenticationSlice.actions.setLoggedIn(loggedIn));
    },
    [dispatch],
  );

  const setForbidden = useCallback(
    (forbidden: boolean) => {
      return dispatch(authenticationSlice.actions.setForbidden(forbidden));
    },
    [dispatch],
  );

  const openAccountCloseConfirmForm = useCallback(() => {
    return dispatch(authenticationSlice.actions.openAccountCloseConfirmForm());
  }, [dispatch]);

  const closeAccountCloseConfirmForm = useCallback(() => {
    return dispatch(authenticationSlice.actions.closeAccountCloseConfirmForm());
  }, [dispatch]);

  return useMemo(
    () => ({
      isLoggedIn,
      isForbidden,
      isAccountCloseConfirmFormOpen,
      setLoggedIn,
      setForbidden,
      openAccountCloseConfirmForm,
      closeAccountCloseConfirmForm,
    }),
    [
      isLoggedIn,
      isForbidden,
      isAccountCloseConfirmFormOpen,
      setLoggedIn,
      setForbidden,
      openAccountCloseConfirmForm,
      closeAccountCloseConfirmForm,
    ],
  );
}

export default useAuthentication;
