import { SocialSecuritySettings } from '../../shared/dtos/taxes-settings.interface';

import { api } from '../api';

export const taxesSettingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTaxesSocialSecuritySettings: builder.query<SocialSecuritySettings, void>({
      query: () => ({
        url: 'taxes/settings/social-security',
      }),
      providesTags: ['SocialSecuritySettings'],
    }),
    updateTaxesSocialSecuritySettings: builder.mutation<SocialSecuritySettings, SocialSecuritySettings>({
      query: (body) => ({
        url: 'taxes/settings/social-security',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SocialSecuritySettings', 'Taxes'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetTaxesSocialSecuritySettingsQuery, useUpdateTaxesSocialSecuritySettingsMutation } =
  taxesSettingsApi;
