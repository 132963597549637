import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import useNotifications from '../../redux/notifications/notifications.hooks';

interface NotificationProps {
  autoHideDuration?: number;
}

export default function Notification(props: NotificationProps) {
  const { autoHideDuration } = props;
  const { isOpen, message, severity, duration, hide } = useNotifications();

  const handleClose = () => {
    hide();
  };

  return (
    <Snackbar
      open={isOpen}
      onClose={handleClose}
      autoHideDuration={duration ?? autoHideDuration ?? 6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MuiAlert elevation={6} variant="filled" severity={severity}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
}
