// The algorithm can be taken from https://pt.wikipedia.org/wiki/N%C3%BAmero_de_identifica%C3%A7%C3%A3o_fiscal
export function validateVat(value?: string | number): boolean {
  if (!value) {
    return false;
  }

  const vat = stringify(value);
  return vat.length === 9 && hasValidStartDigits(vat) && hasValidCheckDigit(vat);
}

function stringify(vat: string | number): string {
  return typeof vat === 'string' ? vat : vat.toString();
}

function hasValidStartDigits(vat: string) {
  return isFromRegularPeople(vat) || isFromDemo(vat);
}

function isFromRegularPeople(vat: string): boolean {
  return ['1', '2', '3'].includes(vat.substr(0, 1));
}

function isFromDemo(vat: string): boolean {
  // careful - this is also used for civil society without legal representation
  return ['99'].includes(vat.substr(0, 2));
}

function hasValidCheckDigit(vat: string): boolean {
  const lastNumber = Number(vat[8]);
  return computeCheckDigit(vat) === lastNumber;
}

function computeCheckDigit(vat: string): number {
  const digits = mapToDigits(vat);
  const total =
    digits[0] * 9 +
    digits[1] * 8 +
    digits[2] * 7 +
    digits[3] * 6 +
    digits[4] * 5 +
    digits[5] * 4 +
    digits[6] * 3 +
    digits[7] * 2;

  const modulo11 = Number(total) % 11;

  return modulo11 < 2 ? 0 : 11 - modulo11;
}

function mapToDigits(vat: string): number[] {
  return vat.split('').map((c) => Number.parseInt(c));
}
