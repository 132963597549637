import Forbidden from './forbidden';
import { Navigate } from 'react-router-dom';
import useAuthentication from '../../redux/authentication/authentication.hooks';

interface PrivateRouteProps {
  children: JSX.Element;
  redirectTo: string;
  isBlocked?: boolean;
}

export default function PrivateRoute(props: PrivateRouteProps): JSX.Element {
  const { children, redirectTo, isBlocked } = props;
  const { isLoggedIn, isForbidden } = useAuthentication();

  if (!isLoggedIn) {
    return <Navigate to={redirectTo} />;
  }

  if (isForbidden || isBlocked) {
    return <Forbidden />;
  }

  return children;
}
