import { Box, CircularProgress } from '@material-ui/core';

export interface LoadingContainerProps {
  isLoading: boolean;
  height?: string | number;
  size?: number;
  children?: React.ReactNode | React.ReactNode[];
}

export function LoadingContainer(props: LoadingContainerProps) {
  const { isLoading, height, size, children } = props;

  if (isLoading) {
    return (
      <Box width="100%" height={height ?? '100%'} display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={size ?? 80} />
      </Box>
    );
  }

  if (children) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return null;
}
