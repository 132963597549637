import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { DashboardState } from './dashboard.interfaces';

const initialState: DashboardState = {
  quarter: 1,
  year: new Date().getFullYear(),
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setYear(state, { payload }: PayloadAction<number>) {
      state.year = payload;
    },
    setQuarter(state, { payload }: PayloadAction<number>) {
      state.quarter = payload;
    },
  },
});

export default dashboardSlice.reducer;
