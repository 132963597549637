import { ChangePassword } from '../../shared/dtos/change-password.interface';
import { CloseAccount, Profile } from '../../shared/dtos/user.interface';
import { Login } from '../../shared/dtos/login.interface';
import { Registration, RegistrationVerify } from '../../shared/dtos/registration.interface';
import { ResetPassword, ResetPasswordParams, TriggerPasswordReset } from '../../shared/dtos/reset-password.interface';
import { UpdateProfile } from '../../shared/dtos/update-profile.interface';

import { api } from '../api';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation<void, Registration>({
      query: (body) => ({
        url: 'auth/register',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'Profile',
        'UserDetails',
        'PaymentMethods',
        'Expenses',
        'Entities',
        'Invoices',
        'Taxes',
        'Integrations',
        'Dashboard',
      ],
    }),
    verify: builder.mutation<void, RegistrationVerify>({
      query: (body) => ({
        url: 'auth/verify',
        method: 'POST',
        body,
      }),
    }),
    login: builder.mutation<void, Login>({
      query: (body) => ({
        url: 'auth/login',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'Profile',
        'UserDetails',
        'PaymentMethods',
        'Expenses',
        'Entities',
        'Invoices',
        'Taxes',
        'Integrations',
        'Dashboard',
      ],
    }),
    loginDemoAccount: builder.mutation<void, Login>({
      query: (body) => ({
        url: 'auth/login',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'Profile',
        'UserDetails',
        'PaymentMethods',
        'Expenses',
        'Entities',
        'Invoices',
        'Taxes',
        'Integrations',
        'Dashboard',
      ],
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: 'auth/logout',
        method: 'POST',
      }),
      invalidatesTags: [
        'Profile',
        'UserDetails',
        'PaymentMethods',
        'Expenses',
        'Entities',
        'Invoices',
        'Taxes',
        'Integrations',
        'Dashboard',
      ],
    }),
    triggerPasswordReset: builder.mutation<void, TriggerPasswordReset>({
      query: (body) => ({
        url: 'auth/trigger-password-reset',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation<void, ResetPassword>({
      query: (body) => ({
        url: 'auth/password-reset',
        method: 'POST',
        body,
      }),
    }),
    checkResetPassword: builder.query<void, ResetPasswordParams>({
      query: (params) => ({
        url: 'auth/password-reset',
        method: 'GET',
        params,
      }),
    }),
    getProfile: builder.query<Profile, void>({
      query: () => ({
        url: 'auth/profile',
      }),
      providesTags: ['Profile'],
    }),
    changePassword: builder.mutation<void, ChangePassword>({
      query: (body) => ({
        url: 'auth/change-password',
        method: 'POST',
        body,
      }),
    }),
    updateProfile: builder.mutation<void, UpdateProfile>({
      query: (body) => ({
        url: 'auth/profile',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Profile'],
    }),
    closeAccount: builder.mutation<void, CloseAccount>({
      query: (body) => ({
        url: 'auth/close-account',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Profile'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useRegisterMutation,
  useVerifyMutation,
  useLoginMutation,
  useLoginDemoAccountMutation,
  useLogoutMutation,
  useGetProfileQuery,
  useTriggerPasswordResetMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useUpdateProfileMutation,
  useCheckResetPasswordQuery,
  useCloseAccountMutation,
} = authApi;
