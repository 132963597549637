import { AccountTab, ROUTE_ACCOUNT, ROUTE_HOME, ROUTE_LOGIN, ROUTE_REGISTRATION } from './routes';
import { useCallback, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

function useNavigation() {
  const navigate = useNavigate();

  const navToHome = useCallback(async () => {
    navigate(ROUTE_HOME);
  }, [navigate]);

  const navToLogin = useCallback(async () => {
    navigate(ROUTE_LOGIN);
  }, [navigate]);

  const navToRegister = useCallback(async () => {
    navigate(ROUTE_REGISTRATION);
  }, [navigate]);

  const navToAccount = useCallback(
    async (tab?: AccountTab) => {
      const targetTab = tab ?? 'profile';
      navigate({ pathname: ROUTE_ACCOUNT, search: `?tab=${targetTab}` });
    },
    [navigate],
  );

  return useMemo(
    () => ({
      navToAccount,
      navToLogin,
      navToHome,
      navToRegister,
    }),
    [navToAccount, navToLogin, navToHome],
  );
}

export default useNavigation;
