import { Plan } from '../../shared/dtos/user.interface';
import { api } from '../api';

export const plansApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPlans: builder.query<Plan[], void>({
      query: () => ({
        url: 'plans',
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetPlansQuery } = plansApi;
