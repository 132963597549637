import { Box, Typography } from '@material-ui/core';

import ErrorIcon from '@material-ui/icons/Error';
import { useTheme } from '@material-ui/core/styles';

export default function Forbidden() {
  const theme = useTheme();

  return (
    <Box component="main" width="100%" height="100%" paddingTop={theme.spacing(5)}>
      <Box textAlign="center">
        <ErrorIcon fontSize="large" />
        <Typography>Esta informação está inacessível de momento.</Typography>
      </Box>
    </Box>
  );
}
