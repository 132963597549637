import { Grid, GridSpacing } from '@material-ui/core';

interface VBoxProps {
  className?: string;
  item?: boolean;
  spacing?: GridSpacing;
  children: React.ReactElement[] | React.ReactElement;
}

export default function VBox(props: VBoxProps) {
  const { children, spacing, item, className } = props;

  return (
    <Grid container spacing={spacing} item={item} className={className}>
      {Array.isArray(children)
        ? children.map((child, index) => (
            <Grid key={index} item xs={12}>
              {child}
            </Grid>
          ))
        : children}
    </Grid>
  );
}
