import { Box, IconButton, TableCell, Tooltip } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { RowAction } from './data-table.interfaces';

interface DataTableActionsProps<T> {
  index: number;
  row: T;
  actions: RowAction[];
  onDelete?: (row: T, index: number) => void | Promise<void>;
  onEdit?: (row: T, index: number) => void | Promise<void>;
}

export default function DataTableActions<T>(props: DataTableActionsProps<T>) {
  const { index, row, actions, onDelete, onEdit } = props;

  return (
    <TableCell align="right">
      <Box>
        {actions.includes('edit') && (
          <Tooltip title="Editar">
            <IconButton size="small" onClick={() => onEdit && onEdit(row, index)} disabled={!onEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {actions.includes('delete') && (
          <Tooltip title="Apagar">
            <IconButton size="small" onClick={() => onDelete && onDelete(row, index)} disabled={!onDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </TableCell>
  );
}
