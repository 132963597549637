import * as yup from 'yup';

import { Box, Grid, Tooltip } from '@material-ui/core';

import { yupResolver } from '@hookform/resolvers/yup';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { format } from 'date-fns';
import { Employment } from '../../shared/dtos/user-details.interface';
import { DataTableHeaderDefinition } from '../data/data-table.interfaces';
import DataTableWithForm from '../forms/data-table-with-form';
import FormDatePicker from '../forms/form-date-picker';
import FormTextField from '../forms/form-text-field';

interface EmploymentDetailsFormProps {
  items: Employment[];
  onSubmit: (data: Employment) => void;
  onDelete: (data: Employment) => void;

  disabled?: boolean;
}

const headers: DataTableHeaderDefinition<Employment>[] = [
  {
    id: 'startedAt',
    name: 'Data de inicio',
    align: 'left',
    format: (item) => format(item.startedAt, 'yyyy-MM-dd'),
  },
  {
    id: 'endedAt',
    name: 'Data de fim',
    format: (item) => (item.endedAt ? format(item.endedAt, 'yyyy-MM-dd') : '-'),
    align: 'left',
  },
  {
    id: 'baseSalary',
    name: 'Salário Mensal',
    format: (item) => item.baseSalary,
    align: 'center',
  },
  {
    id: 'retainedIncomeTax',
    name: 'Imposto retido',
    format: (item) => item.retainedIncomeTax ?? '-',
    align: 'center',
  },
  {
    id: 'extraSalary',
    name: 'Extras Mensais',
    format: (item) => item.extraSalary ?? '-',
    align: 'center',
  },
];

const schema = yup
  .object({
    startedAt: yup.date().required(),
    endedAt: yup
      .date()
      .optional()
      .when(
        'startedAt',
        (startedAt, schema) => startedAt && schema.min(startedAt, 'Data de fim deve ser depois da data de início'),
      ),
    baseSalary: yup
      .number()
      .transform((value, original) => (!original?.length ? undefined : value))
      .required()
      .positive(),
    retainedIncomeTax: yup
      .number()
      .transform((value, original) => (!original?.length ? undefined : value))
      .optional()
      .positive(),
    extraSalary: yup
      .number()
      .transform((value, original) => (!original?.length ? undefined : value))
      .optional()
      .positive(),
  })
  .required();

export default function EmploymentDetailsForm(props: EmploymentDetailsFormProps) {
  const { items, onSubmit, onDelete, disabled } = props;

  return (
    <DataTableWithForm
      headers={headers}
      items={items}
      onSubmit={onSubmit}
      onDelete={onDelete}
      emptyMessage="Sem informação salarial"
      disabled={disabled}
      resolver={yupResolver(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <FormDatePicker name="startedAt" label="Data de início" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormDatePicker name="endedAt" label="Data de fim" clearable fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={6} container spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <FormTextField name="baseSalary" label="Salário mensal" fullWidth defaultValue={''} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FormTextField name="retainedIncomeTax" label="Desconto mensal" fullWidth defaultValue={''} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Box display="flex" flexDirection="horizontal" alignItems="flex-end">
              <FormTextField name="extraSalary" label="Extras mensais" fullWidth defaultValue={''} />
              <Tooltip title="Nos extras mensais, podes incluir o somatório dos prémios, bónus e semelhantes, isenção de horário de trabalho, diuturnidades, tickets de educação e/ou ensino e abono para falhas.">
                <HelpOutlineIcon />
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </DataTableWithForm>
  );
}
