import { createSlice } from '@reduxjs/toolkit';
import { calendarApi } from './calendar.api';
import { CalendarState } from './calendar.interface';

const initialState: CalendarState = {};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(calendarApi.endpoints.getCalendar.matchFulfilled, (state, { payload }) => {
      if (state.internalUrl) {
        URL.revokeObjectURL(state.internalUrl);
      }

      state.internalUrl = URL.createObjectURL(new Blob([payload.content], { type: 'text/calendar' }));
    });
  },
});

export default calendarSlice.reducer;
