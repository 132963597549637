import { Business, Employment, TaxData } from '../../shared/dtos/user-details.interface';
import { RootState } from '../store';
import { createSelector } from 'reselect';

export const taxFormSelector = (state: RootState) => state.taxForm;

export const businessHistorySelector = createSelector(
  [taxFormSelector],
  ({ businessHistory: businessDetails }): Business[] => {
    return businessDetails.map(({ startedAt, endedAt, ...other }) => ({
      startedAt: new Date(startedAt),
      endedAt: endedAt ? new Date(endedAt) : undefined,
      ...other,
    }));
  },
);

export const taxDataHistorySelector = createSelector([taxFormSelector], ({ taxDataHistory: taxDetails }): TaxData[] => {
  return taxDetails.map(({ startedAt, ...other }) => ({
    startedAt: new Date(startedAt),
    ...other,
  }));
});

export const employmentHistorySelector = createSelector(
  [taxFormSelector],
  ({ employmentHistory: employmentDetails }): Employment[] => {
    return employmentDetails.map(({ startedAt, endedAt, ...other }) => ({
      startedAt: new Date(startedAt),
      endedAt: endedAt ? new Date(endedAt) : undefined,
      ...other,
    }));
  },
);
