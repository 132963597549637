import { Integration, IntegrationCreate, IntegrationVendor } from '../../shared/dtos/integrations.interface';
import { api } from '../api';

export const integrationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getIntegrations: builder.query<Integration[], void>({
      query: () => ({
        url: 'integrations',
      }),
      providesTags: ['Integrations'],
    }),
    deregisterIntegration: builder.mutation<void, string>({
      query: (vendor) => ({
        url: `integrations/${vendor}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Integrations'],
    }),
    createIntegration: builder.mutation<void, { body: IntegrationCreate; vendor: IntegrationVendor }>({
      query: ({ body, vendor }) => ({
        url: `integrations/${vendor}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Integrations'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetIntegrationsQuery, useDeregisterIntegrationMutation, useCreateIntegrationMutation } =
  integrationsApi;
