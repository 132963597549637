import { Calendar } from '../../shared/dtos/calendar.interface';
import { api } from '../api';

export const calendarApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCalendar: builder.query<Calendar, void>({
      query: () => ({
        url: `calendar`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetCalendarQuery, useLazyGetCalendarQuery } = calendarApi;
