import { PortalAuthLogin, PortalAuthStatus } from '../../shared/dtos/portal-auth.interface';
import { api } from '../api';

export const portalActivitiesProviderApi = api.injectEndpoints({
  endpoints: (builder) => ({
    portalAuthLogin: builder.mutation<void, PortalAuthLogin>({
      query: (body: PortalAuthLogin) => ({
        url: 'portal-auth/login',
        method: 'POST',
        body,
      }),
    }),
    portalAuthStatus: builder.query<PortalAuthStatus, void>({
      query: () => ({
        url: `/portal-auth/status`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { usePortalAuthLoginMutation, useLazyPortalAuthStatusQuery } = portalActivitiesProviderApi;
