import { useAppDispatch, useAppSelector } from '../hooks';
import { useCallback, useMemo } from 'react';

import { ShowNotification } from './notifications.interfaces';
import { notificationsSelector } from './notifications.selectors';
import { notificationsSlice } from './notifications.slice';

function useNotifications() {
  const { isOpen, message, severity, duration } = useAppSelector(notificationsSelector);
  const dispatch = useAppDispatch();

  const show = useCallback(
    (data: ShowNotification) => {
      dispatch(notificationsSlice.actions.show(data));
    },
    [dispatch],
  );

  const hide = useCallback(() => {
    dispatch(notificationsSlice.actions.hide());
  }, [dispatch]);

  return useMemo(
    () => ({ show, hide, isOpen, message, severity, duration }),
    [show, hide, isOpen, message, severity, duration],
  );
}

export default useNotifications;
