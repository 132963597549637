import { PaymentInvoiceList, PaymentInvoicesParams } from '../../shared/dtos/payment-invoices.interface';

import { api } from '../api';

export const paymentInvoicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listPaymentInvoices: builder.query<PaymentInvoiceList, PaymentInvoicesParams>({
      query: (params) => ({
        url: 'billing/invoices',
        params: { ...params },
      }),
      providesTags: ['PaymentInvoices'],
    }),
  }),
  overrideExisting: false,
});

export const { useListPaymentInvoicesQuery } = paymentInvoicesApi;
