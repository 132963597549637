import { Business, Employment, TaxData } from '../../shared/dtos/user-details.interface';
import {
  businessHistorySelector,
  employmentHistorySelector,
  taxDataHistorySelector,
  taxFormSelector,
} from './tax-from.selectors';
import { useAppDispatch, useAppSelector } from '../hooks';
import { useCallback, useMemo } from 'react';

import { taxFormSlice } from './tax-form.slice';

function useTaxForm() {
  const dispatch = useAppDispatch();
  const { isOpen, step } = useAppSelector(taxFormSelector);
  const businessHistory = useAppSelector(businessHistorySelector);
  const taxDataHistory = useAppSelector(taxDataHistorySelector);
  const employmentHistory = useAppSelector(employmentHistorySelector);

  const open = useCallback(async () => {
    return dispatch(taxFormSlice.actions.open());
  }, [dispatch]);

  const cancel = useCallback(() => {
    return dispatch(taxFormSlice.actions.cancel());
  }, [dispatch]);

  const setBusinessHistory = useCallback(
    (data: Business[]) => {
      return dispatch(
        taxFormSlice.actions.setBusinessHistory(
          data.map(({ startedAt, endedAt, ...other }) => ({
            startedAt: startedAt.toISOString(),
            endedAt: endedAt?.toISOString(),
            ...other,
          })),
        ),
      );
    },
    [dispatch],
  );

  const setTaxDataHistory = useCallback(
    (data: TaxData[]) => {
      return dispatch(
        taxFormSlice.actions.setTaxDataHistory(
          data.map(({ startedAt, ...other }) => ({
            startedAt: startedAt.toISOString(),
            ...other,
          })),
        ),
      );
    },
    [dispatch],
  );

  const setEmploymentHistory = useCallback(
    (data: Employment[]) => {
      return dispatch(
        taxFormSlice.actions.setEmploymentHistory(
          data.map(({ startedAt, endedAt, ...other }) => ({
            startedAt: startedAt.toISOString(),
            endedAt: endedAt?.toISOString(),
            ...other,
          })),
        ),
      );
    },
    [dispatch],
  );

  const incrementStep = useCallback(() => {
    return dispatch(taxFormSlice.actions.incrementStep());
  }, [dispatch]);

  const decrementStep = useCallback(() => {
    return dispatch(taxFormSlice.actions.decrementStep());
  }, [dispatch]);

  return useMemo(
    () => ({
      isOpen,
      step,
      businessHistory,
      taxDataHistory,
      employmentHistory,
      open,
      cancel,
      setBusinessHistory,
      setTaxDataHistory,
      setEmploymentHistory,
      incrementStep,
      decrementStep,
    }),
    [
      isOpen,
      step,
      businessHistory,
      taxDataHistory,
      employmentHistory,
      open,
      cancel,
      setBusinessHistory,
      setTaxDataHistory,
      setEmploymentHistory,
      incrementStep,
      decrementStep,
    ],
  );
}

export default useTaxForm;
