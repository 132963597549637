import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptLocale from 'date-fns/locale/pt';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import ConfirmationDialog from './components/dialogs/confirmation-dialog';
import PortalLoginDialog from './components/dialogs/portal-login-dialog';
import PrivateRoute from './components/navigation/private-route';
import Notification from './components/notifications/notification';
import MainLayout from './layouts/main-layout';
import { publicRoutes } from './navigation/routes';
import store from './redux/store';

const theme = createTheme({
  palette: {
    background: {
      default: '#F7F7F7',
    },
    primary: {
      main: '#2184FE',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: '#455670',
    },
    success: {
      main: '#43DEB7',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#455670',
    },
    info: {
      main: '#FF672F',
    },
  },
  typography: {
    h1: {
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: '22px',
      letterSpacing: '-0.34px',
    },
    h2: {
      fontSize: '30px',
      fontWeight: 'bold',
      lineHeight: '36px',
    },
    h3: {
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: '24px',
    },
    h4: {
      fontSize: '16px',
      fontWeight: 'normal',
      letterSpacing: '-0.29px',
      lineHeight: '20px',
    },
    body1: {
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '22px',
      letterSpacing: '-0.24px',
    },
    body2: {
      letterSpacing: '0.24px',
      fontSize: '12px',
    },
    subtitle1: {
      letterSpacing: '0.24px',
      textTransform: 'uppercase',
      fontSize: '10px',
    },
    subtitle2: {
      fontSize: '17px',
      fontWeight: 'normal',
      letterSpacing: '-0.29px',
      lineHeight: '22px',
    },
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: 'none',
        fontWeight: 'bold',
        letterSpacing: '0px',
        fontSize: '14px',
      },
    },
  },
});

export function App() {
  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Suspense fallback={<div></div>}>
              <Routes>
                {publicRoutes.map((route) => (
                  <Route key={route.path} path={route.path} element={route.component} />
                ))}
                <Route
                  path={'/*'}
                  element={
                    <PrivateRoute redirectTo="/login">
                      <MainLayout />
                    </PrivateRoute>
                  }
                ></Route>
              </Routes>
            </Suspense>
          </BrowserRouter>
          <Notification />
          <ConfirmationDialog />
          <PortalLoginDialog />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Provider>
  );
}

export default App;
