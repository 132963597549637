import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FieldValues, FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';

export interface DialogFormProps<T extends FieldValues> {
  open: boolean;
  title: string;
  submitName: string;
  children: React.ReactElement | React.ReactElement[] | null;
  methods: UseFormReturn<T>;
  extraButtons?: React.ReactElement | React.ReactElement[] | null;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  onClose: () => void;
  onSubmit: SubmitHandler<T>;
  onUpdate?: (data: T) => void;
}

export default function DialogForm<T extends FieldValues>(props: DialogFormProps<T>) {
  const { title, children, submitName, methods, extraButtons, onSubmit, ...extra } = props;

  return (
    <Dialog {...extra}>
      <DialogTitle>{title}</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            {extraButtons}
            <Button onClick={extra.onClose} color="primary" disabled={methods.formState.isSubmitting}>
              Cancelar
            </Button>
            <Button color="primary" type="submit" disabled={methods.formState.isSubmitting}>
              {submitName}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}
