import { InputAdornment, TextField } from '@material-ui/core';
import { codesByCountry } from '../../shared/helpers/phone.helpers';

import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface PhoneCodeInputProps {
  id?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  name?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  value?: CountryType;
  onChange?: (event: React.ChangeEvent<unknown>, value: unknown) => void;
}

interface CountryType {
  code: string;
  label: string;
  phone: string;
}

function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function PhoneCodeInput(props: PhoneCodeInputProps) {
  const { label, error, helperText, name, ...other } = props;
  const classes = useStyles();

  function findCode(value: string) {
    let toFind = value;
    let code = '';
    if (toFind) {
      toFind = toFind.substring(1);
      const countryCode = codesByCountry.find((c) => c.phone === toFind);
      if (countryCode) {
        code = countryToFlag(countryCode.code);
      }
    }

    return code;
  }

  return (
    <Autocomplete
      {...other}
      options={codesByCountry as CountryType[]}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      disableClearable
      getOptionLabel={(option) => `+${option.phone}`}
      renderOption={(option) => (
        <React.Fragment>
          <span>{countryToFlag(option.code)}</span>+{option.phone}
        </React.Fragment>
      )}
      renderInput={(params) => {
        const code = findCode((params.inputProps as any).value);
        return (
          <TextField
            {...params}
            label={label}
            error={error}
            name={name}
            helperText={helperText}
            inputProps={{
              ...params.inputProps,
              style: { textAlign: 'right' },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: <InputAdornment position="start">{code}</InputAdornment>,
            }}
          />
        );
      }}
      defaultValue={codesByCountry[182]}
    />
  );
}
