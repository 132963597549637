import { yupResolver } from '@hookform/resolvers/yup';
import { DialogContentText, FormControl } from '@material-ui/core';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { usePortalAuthLoginMutation } from '../../redux/activity-provider/activity-provider.api';
import useNotifications from '../../redux/notifications/notifications.hooks';
import DialogForm from '../forms/dialog-form';
import FormTextField from '../forms/form-text-field';
import usePortalLoginDialog from '../../redux/portal-login-dialog/portal-login-dialog.hooks';

const schema = yup
  .object({
    password: yup.string().required(),
  })
  .required();

interface PortalLoginDialogForm {
  password: string;
}

export default function PortalLoginDialog() {
  const methods = useForm<PortalLoginDialogForm>({ resolver: yupResolver(schema) });
  const { isOpen, confirm, cancel } = usePortalLoginDialog();
  const { show } = useNotifications();
  const [portalAuthLogin] = usePortalAuthLoginMutation();

  useEffect(() => {
    methods.reset();
  }, [isOpen, methods]);

  const handleCancel = () => {
    cancel();
  };

  const handleConfirm = async (data: PortalLoginDialogForm) => {
    try {
      await portalAuthLogin({ password: data.password });
      confirm();
    } catch (error) {
      show({
        severity: 'error',
        message: 'Credenciais inválidas. Tente novamente.',
      });
    }
  };

  return (
    <DialogForm
      title="Autenticação no Portal das Finaças"
      submitName="Autenticar"
      methods={methods}
      open={isOpen}
      onClose={handleCancel}
      onSubmit={handleConfirm}
    >
      <DialogContentText>
        Para proceder com a ação, por favor, autentique-se com as suas credenciais do Portal das Finanças.
      </DialogContentText>
      <FormControl fullWidth margin="normal">
        <FormTextField name="password" type="password" label="Palavra-passe das Finanças" autoFocus />
      </FormControl>
    </DialogForm>
  );
}
