import { Controller, UseControllerProps, useFormContext } from 'react-hook-form';

import { useMemo } from 'react';
import { codesByCountry } from '../../shared/helpers/phone.helpers';
import PhoneCodeInput from '../inputs/phone-code-input';

interface FormPhoneCodeProps extends UseControllerProps {
  label?: string;
  name: string;
  helperText?: string | null;
  fullWidth?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
}

export default function FormPhoneCode(props: FormPhoneCodeProps) {
  const { label, fullWidth, autoFocus, disabled, helperText, ...other } = props;
  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const path = useMemo(() => other.name?.split('.'), [other]);
  const error = useMemo(() => errors[path[Math.max(path.length - 1, 0)]]?.message as string, [errors, path]);
  const helpTextToShow = useMemo(() => error || helperText || '', [error, helperText]);

  return (
    <Controller
      {...other}
      control={control}
      defaultValue={codesByCountry[182]}
      render={({ field: { ref, ...field } }) => (
        <PhoneCodeInput
          {...field}
          label={label}
          fullWidth={fullWidth}
          error={!!error}
          helperText={helpTextToShow}
          disabled={isSubmitting || disabled}
          value={field.value}
          onChange={(_event, value) => {
            field.onChange(value);
          }}
        />
      )}
    />
  );
}
