import { DataTableHeaderDefinition, DataTableRowDefinition } from './data-table.interfaces';
import { TableCell } from '@material-ui/core';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';

import React from 'react';

interface DataTableCellProps<T> {
  row: DataTableRowDefinition<T>;
  rowIndex: number;
  header: DataTableHeaderDefinition<T>;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    padding: '16px',
  },
  text: {
    margin: 0,
    padding: '16px',
  },
  container: {
    display: 'flex',
    height: '62px',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      fontWeight: 'bold',
    },
    body: {
      fontSize: '0.875rem',
      overflowX: 'hidden',
      wordBreak: 'break-word',
    },
  }),
)(TableCell);

export function DataTableCell<T>({ header, row, rowIndex }: DataTableCellProps<T>) {
  const classes = useStyles();

  const value = header.format(row.data);

  return (
    <StyledTableCell
      className={classes.root}
      key={`${header.id}-${rowIndex}`}
      component="td"
      align={header.align ?? 'left'}
    >
      {value}
    </StyledTableCell>
  );
}
