import { CreatePaymentMethod, PaymentMethod } from '../../shared/dtos/payment-methods.interface';
import { ExternalPaymentMethods } from '../../shared/dtos/external-payment-methods.interface';

import { api } from '../api';

export const paymentMethodsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createPaymentMethod: builder.mutation<PaymentMethod, CreatePaymentMethod>({
      query: (body) => ({
        url: 'billing/payment-methods',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PaymentMethods', 'Profile'],
    }),
    deletePaymentMethod: builder.mutation<void, string>({
      query: (id) => ({
        url: `billing/payment-methods/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PaymentMethods'],
    }),
    listPaymentMethods: builder.query<PaymentMethod[], void>({
      query: () => ({
        url: 'billing/payment-methods',
      }),
      providesTags: ['PaymentMethods'],
    }),
    getPaymentMethod: builder.query<PaymentMethod, string>({
      query: (id) => ({
        url: `billing/payment-methods/${id}`,
      }),
      providesTags: ['PaymentMethods'],
    }),
    getExternalPaymentMethod: builder.query<ExternalPaymentMethods[], string | undefined>({
      query: (param) => ({
        url: `payment_methods?client_name=${param}`,
      }),
    }),
    selectPaymentMethod: builder.mutation<void, string>({
      query: (id) => ({
        url: `billing/payment-methods/${id}/select`,
        method: 'POST',
      }),
      invalidatesTags: ['PaymentMethods'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreatePaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useGetPaymentMethodQuery,
  useGetExternalPaymentMethodQuery,
  useListPaymentMethodsQuery,
  useLazyListPaymentMethodsQuery,
  useSelectPaymentMethodMutation,
} = paymentMethodsApi;
