import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import React from 'react';
import useConfirmationDialog from '../../redux/confirmation-dialog/confirmation-dialog.hooks';

export default function ConfirmationDialog() {
  const { isOpen, title, message, confirm, cancel } = useConfirmationDialog();

  const handleCancel = () => {
    cancel();
  };

  const handleConfirm = () => {
    confirm();
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
