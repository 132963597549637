import React, { useEffect, useState } from 'react';
import { UseControllerProps, useFormContext } from 'react-hook-form';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface FormDatePickerProps extends UseControllerProps {
  label?: string;
  helperText?: string | null;
  fullWidth?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  disableFuture?: boolean;
  defaultValue?: MaterialUiPickersDate;
  yearOnly?: boolean;
  clearable?: boolean;
  minDate?: Date;
}

export default function FormDatePicker(props: FormDatePickerProps) {
  const { name, rules, defaultValue, yearOnly, minDate, ...other } = props;
  const [date, setDate] = useState<MaterialUiPickersDate>(defaultValue ?? null);
  const {
    register,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
  } = useFormContext();
  const value = getValues(name) as Date;
  const hasError = !!errors[name];

  useEffect(() => {
    register(name, rules);
  }, [name, rules, register]);

  useEffect(() => {
    setDate(value || null);
  }, [setDate, value]);

  const getHelpText = () => {
    if (errors[name]?.message) {
      return errors[name]?.message;
    }

    let message: string | undefined;
    switch (errors[name]?.type) {
      case 'required':
        message = 'Campo obrigatório';
        break;
      default:
        break;
    }

    return message;
  };

  return (
    <KeyboardDatePicker
      error={hasError}
      helperText={getHelpText()}
      value={date}
      id={name}
      variant="dialog"
      format={yearOnly ? 'yyyy' : 'yyyy-MM-dd'}
      autoOk
      onChange={(date) => {
        setValue(name, date, { shouldValidate: true, shouldDirty: true });
        setDate(date);
      }}
      minDate={minDate}
      disabled={isSubmitting}
      views={yearOnly ? ['year'] : undefined}
      {...other}
    />
  );
}
