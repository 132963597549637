import { FullTaxFormStepProps } from './tax-form.interfaces';
import { TaxData } from '../../shared/dtos/user-details.interface';
import TaxDetailsForm from '../form-tax-details/tax-details.form';
import { useImperativeHandle } from 'react';
import useTaxForm from '../../redux/tax-form/tax-form.hooks';

export default function TaxDetailsStep(props: FullTaxFormStepProps) {
  const { mRef } = props;
  const { setTaxDataHistory: setTaxDetails, taxDataHistory: taxDetails } = useTaxForm();

  useImperativeHandle(mRef, () => ({
    submit: (): Promise<string | undefined | null> => {
      if (!taxDetails.length) {
        return Promise.resolve('Indique pelo menos um ano de detalhes fiscais');
      }

      return Promise.resolve(null);
    },
  }));

  const handleAdd = (data: TaxData) => {
    setTaxDetails([...taxDetails, data].sort((a, b) => b.startedAt.getTime() - a.startedAt.getTime()));
  };

  const handleRemove = (data: TaxData) => {
    setTaxDetails(taxDetails.filter((item) => item !== data));
  };

  const handleUpdate = (prev: TaxData, data: TaxData) => {
    setTaxDetails(taxDetails.map((item) => (item === prev ? data : item)));
  };

  return (
    <TaxDetailsForm
      items={taxDetails}
      onAdd={handleAdd}
      onUpdate={handleUpdate}
      onRemove={handleRemove}
    ></TaxDetailsForm>
  );
}
