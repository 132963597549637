import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import React from 'react';

interface NavListTitleProps {
  text: string;
  className: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '16px',
  },
}));

export default function NavListTitle({ text, className }: NavListTitleProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" gutterBottom className={className}>
        {text}
      </Typography>
    </div>
  );
}
