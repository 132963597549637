import { Partner } from '../../shared/dtos/partners.interface';
import { api } from '../api';

export const partnersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPartners: builder.query<Partner[], void>({
      query: () => ({
        url: 'partners',
      }),
      providesTags: ['Partners'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetPartnersQuery } = partnersApi;
